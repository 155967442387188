import Textinput from "@/components/admin/forms/TextInput";
import ReactModal from "@/components/admin/ui/ReactModal";
import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import Validators from "@/components/common/validations/Validator";
import useHandlePostAPI from "@/hooks/useHandlePostAPI";
import { helper } from "@/services";
import apiConfigNew from "@/configs/apiConfigNew";
import { Icon } from "@iconify/react";

const rules = {
	old_password: "required",
	new_password: "required|new_password",
	retype_password: "required|retype_password",
};

const ResetPasswordModal = ({ setModalShow, modalShow }) => {
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRetypeNewPassword, setShowRetypeNewPassword] = useState(false);

	const { formState, isLoader, callAPI, register, validationErrors } = useHandlePostAPI({
		defaultFormState: {
			old_password: "",
			new_password: "",
			retype_password: "",
		},
		method: "put",
		endpoints: apiConfigNew.admin.reset_password,
		callback: ({ message }) => {
			helper.toaster.success(message);
			setModalShow(false);
		},
	});

	return (
		<Validators formData={formState} rules={rules} setErrors={validationErrors}>
			{({ onSubmit, errors }) => {
				return (
					<ReactModal
						size="md"
						title="Change Password"
						show={modalShow}
						onSave={() => {
							onSubmit(callAPI);
						}}
						onHide={() => {
							setModalShow(false);
						}}
						isLoader={isLoader}
					>
						<Row>
							<Textinput
								className="mb-2 py-1"
								label="Old Password"
								{...register("old_password", errors)}
								type={showOldPassword ? "text" : "password"}
							>
								<Button
									variant="transparent"
									size="sm"
									className="old--password"
									onClick={() => {
										setShowOldPassword(!showOldPassword);
									}}
								>
									{showOldPassword ? <Icon icon="mdi:eye" /> : <Icon icon="mdi:eye-off" />}
								</Button>
							</Textinput>
							<Textinput
								{...register("new_password", errors)}
								type={showNewPassword ? "text" : "password"}
								className="mb-2 py-1"
								label="New Password"
							>
								<Button
									variant="transparent"
									size="sm"
									className="new--password"
									onClick={() => {
										setShowNewPassword(!showNewPassword);
									}}
								>
									{showNewPassword ? <Icon icon="mdi:eye" /> : <Icon icon="mdi:eye-off" />}
								</Button>
							</Textinput>
							<Textinput
								{...register("retype_password", errors)}
								type={showRetypeNewPassword ? "text" : "password"}
								className="py-1"
								label="Retype New Password"
							>
								<Button
									variant="transparent"
									size="sm"
									className="retype--new--password"
									onClick={() => {
										setShowRetypeNewPassword(!showRetypeNewPassword);
									}}
								>
									{showRetypeNewPassword ? <Icon icon="mdi:eye" /> : <Icon icon="mdi:eye-off" />}
								</Button>
							</Textinput>
						</Row>
					</ReactModal>
				);
			}}
		</Validators>
	);
};

export default ResetPasswordModal;
