import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { store } from ".";

export const getAuth = (state) => state?.auth;

export const generalStates = (state) => state?.general;

export const getUserData = () => useSelector(createSelector([getAuth], (auth) => auth.user)) || {};

export const getGeneralState = () => {
	return store.getState().general;
};

export const getAuthState = () => {
	return store.getState().auth;
};

export const getPageNo = (type) => useSelector(createSelector([generalStates], (common) => common.paginationConfig[type])) || 0;

export const getPerPageNo = (type) => useSelector(createSelector([generalStates], (common) => common.paginationConfig[`${type}_per_page`])) || 15;

export const getSearchValue = (type) => useSelector(createSelector([generalStates], (common) => common.paginationConfig[`${type}_search`])) || "";
