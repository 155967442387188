const pageRoutes = {
	admin: {
		login: "/",
		home: "/home",
		symptom: "/symptom",
		profile: "/profile",
		dashboard_doctor: "/doctordashboard",
		services: "/services",
		departments: "/departments",
		enquiry: "/enquiry",
		testimonial: "/testimonial",
		branches: "/branches",
		addBranch: "/branches/add",
		editBranch: "/branches/:id/edit",
		pagesSection: "/pages-section",
		addPagesSection: "/pages-section/add",
		editPagesSection: "/pages-section/:id/edit",
		chooseUs: "/choose-us",
		setting: "/setting",
		counter: "/counter",
		holiday: "/holiday",
		blogCategory: "/blog-category",
		blog: "/blog",
		addBlog: "/blog/add",
		editBlog: "/blog/:id/edit",
		sliders: "/sliders",
		headers: "/headers",
		gallery: "/gallery",
		doctors: "/doctors",
		addDoctors: "/doctors/add",
		editDoctors: "/doctors/:id/edit",
		all_patients: "/view",
		view_patient_detail: "/view/:id",
		staff: "/staff",
		staff_permission: "/staff-permission",
		appointment: "/appointments",
		appointmentMonitor: "/appointmentMonitor",
		today_doctor_appointment: "/today-doctor-appointments",
		create_appointment: "/appointments/create",
		edit_appointment: "/appointments/edit",
		time_schedule: "/time-schedule/:id",
		reference: "/reference",
		intern_doctor: "/intern-doctor",
		letter_format_content: "/letter-pad",
		letter_generate: "/letter-history",
		global_doctor_search: "/doctor-search",
	},
	patient: {
		patient_dashboard: "/patientdashboard",
		appointments: "/patient/appointments",
		patient_files: "/patient/patient-files",
		patient_profile: "/patient/profile",
		patient_login: "/login",
	},
	prescription: {
		appointment: "/prescription-appointment",
		prescription: "/prescription/:id",
		patient: "/patient",
		profile: "/prescription-profile",
	},
	vendor: {
		vendors: "/vendor",
		role: "/role",
		addRole: "/role/add",
		editRole: "/role/edit",
		permission: "/permission",
		user: "/user",
		user_permission: "/user-permission",
	},
};

export default pageRoutes;

export const filterRoute = (route) => {
	return route.substring(1);
};
