import themeConfig from "./themeConfig";

const appConfig = {
	host: process.env.REACT_APP_HOST_API,
	prefix: process.env.REACT_APP_API_PREFIX,
	version: process.env.REACT_APP_API_VERSION,
	patientHomePageUrl: process.env.REACT_APP_PATIENT_HOMEPAGE,
	cookieDomainName: process.env.REACT_APP_COOKIE_DOMAIN,

	dateDisplayFormat: "DD/MM/YYYY",
	dateDisplayEditFormat: "YYYY-MM-DD",
	dateAndTimeDisplayFormat: "DD/MM/YYYY HH:mm A",
	letterDateAndTimeDisplayFormat: "DD/MM/YYYY hh:mm A",
	defaultPerPage: 9999999,
	localStorage: {
		token: "hospital-bearer-token", // for saving bearer token
		role: "hospital-user-role",
	},

	persistKey: {
		authReducer: "auth",
	},

	default_pagination_state: {
		page: 0,
		rowsPerPage: 10,
	},
	perPageArray: [
		{
			label: "5",
			value: 5,
		},
		{
			label: "10",
			value: 10,
		},
		{
			label: "15",
			value: 15,
		},
		{
			label: "20",
			value: 20,
		},
		{
			label: "25",
			value: 25,
		},
	],
	default_pagination_state_for_letter: {
		page: 0,
		rowsPerPage: 8,
	},
	letterPerPageArray: [
		{
			label: "1",
			value: 1,
		},
		{
			label: "8",
			value: 8,
		},
		{
			label: "12",
			value: 12,
		},
		{
			label: "14",
			value: 14,
		},
		{
			label: "18",
			value: 18,
		},
	],

	weekOfDays: [
		{
			label: "Sunday",
			value: "sunday",
		},
		{
			label: "Monday",
			value: "monday",
		},
		{
			label: "Tuesday",
			value: "tuesday",
		},
		{
			label: "Wednesday",
			value: "wednesday",
		},
		{
			label: "Thursday",
			value: "thursday",
		},
		{
			label: "Friday",
			value: "friday",
		},
		{
			label: "Saturday",
			value: "saturday",
		},
	],

	caseType: [
		{
			label: "NEW",
			value: "NEW",
		},
		{
			label: "NORMAL",
			value: "NORMAL",
		},
		{
			label: "EMERGENCY",
			value: "EMERGENCY",
		},
	],
	Status: [
		{
			label: "PENDING",
			value: "PENDING",
		},
		{
			label: "ON GOING",
			value: "ON_GOING",
		},
		{
			label: "COMPLETED",
			value: "COMPLETED",
		},
	],
	defaultShowDateFormat: "MMMM DD, YYYY",
	socket: {
		rooms: {
			admin: "admin",
			public: "public",
		},
		events: {
			appointment: "appointment",
		},
	},
	colorTheme: [
		{
			label: "Light Blue",
			value: "lightBlue",
		},
		{
			label: "Dark Blue",
			value: "darkBlue",
		},
		{
			label: "Light Sky",
			value: "lightSky",
		},
		{
			label: "Orange",
			value: "orange",
		},
		{
			label: "Green",
			value: "green",
		},
	],
	dashboardDepartmentDefaultImage: [themeConfig.pediatrics, themeConfig.urology, themeConfig.emergency, themeConfig.internamMedicine],
	default_pagination_state_for_doctor: {
		page: 0,
		rowsPerPage: 12,
	},
	doctorPerPageArray: [
		{
			label: "6",
			value: "6",
		},
		{
			label: "12",
			value: "12",
		},
		{
			label: "18",
			value: "18",
		},
		{
			label: "24",
			value: "24",
		},
		{
			label: "30",
			value: "30",
		},
	],
	roleOption: [
		{
			label: "STAFF",
			value: "staff",
		},
		{
			label: "PRESCRIPTION",
			value: "prescription",
		},
	],
	staffResign: [
		{
			label: "WORKING",
			value: "false",
		},
		{
			label: "RESIGN",
			value: "true",
		},
	],
	doctorResign: [
		{
			label: "WORKING",
			value: "false",
		},
		{
			label: "RESIGN",
			value: "true",
		},
	],
	doctorFilter: [
		{
			label: "DOCTOR",
			value: "false",
		},
		{
			label: "INTERN",
			value: "true",
		},
	],
};

export default appConfig;
