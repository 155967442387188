import { isEmpty } from "@/services/helper";

const ReactImage = ({ src, className = "", id, image, width, height, onClick }) => {
	return (
		<>
			<img onClick={onClick} id={id} src={!isEmpty(src) ? src : image} className={className} width={width} height={height} alt="..." />
		</>
	);
};

export default ReactImage;
