import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import useWidth from "@/hooks/useWidth";
import BottomNavigation from "@/components/admin/ui/BottomNavigation";

export default function PrescriptionLayout() {
	const { width } = useWidth();

	return (
		<div className="admin-panel">
			<Header />
			<div className="admin--pages-main--wrapper">
				<div className="admin--pages-wrapper">
					<Suspense fallback={"Loading..."}>
						<Outlet />
					</Suspense>
				</div>
			</div>
			{width <= 575 && <BottomNavigation />}
		</div>
	);
}
