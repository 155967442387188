import { helper } from "@/services";
import { isEmpty } from "@/services/helper";
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const BreadcrumbLayout = () => {
	const [state, setState] = useState({
		pageTitle: "",
		breadcrumb: [],
	});

	const location = useLocation();
	const navigate = useNavigate();

	const { currentPageSlug = null } = useSelector((state) => state.layout);

	useEffect(() => {
		const breadcrumb = helper.getbreadCrumbs(currentPageSlug);
		if (!helper.isEmpty(breadcrumb)) {
			setState((prev) => ({
				...prev,
				breadcrumb: breadcrumb,
			}));
		}
	}, [location, currentPageSlug]);

	return (
		<div className="breadcrumb--component">
			{!isEmpty(state.breadcrumb) && (
				<Breadcrumb>
					{state.breadcrumb.map((item, i) => (
						<Breadcrumb.Item
							key={i}
							{...(item.isActive && { active: "active" })}
							onClick={() => {
								navigate(item.path);
							}}
						>
							{item?.name}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
			)}
		</div>
	);
};

export default BreadcrumbLayout;
