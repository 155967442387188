import appConfig from "./appConfig";

const apiConfigNew = {
	baseURL: (appConfig.host + "/" + appConfig.prefix + "/" + appConfig.version + "/").replace(/([^:]\/)\/+/g, "$1"),

	publicURL: (appConfig.host + "/").replace(/([^:]\/)\/+/g, "$1"),

	// auth apis
	login: "login",
	public: {
		home: {
			sliders: "home/sliders",
			sliders_single: "home/sliders/:id",
			service: "home/service",
			service_single: "home/service/:id",
			choose_us: "home/choose_us",
			time_table: "home/timetable",
			doctors: "home/doctor",
			doctor_single: "home/doctor/:id",
			testimonial: "home/testimonial",
			blogs: "blogs",
			branch_time: "home/branchtime",
		},

		doctors: {
			// departments: "list/department",
			departments: "department_doctors",
			doctors: "customer/doctordepartments/doctors",
			doctor_single: "home/doctor/:id",
			// doctor_time: "customer/doctordepartments/doctorstime/:id",
			doctor_time: "home/doctor/:id",
		},
		galleries: {
			galleries: "gallery",
			images: "customer/galleries/allimage",
		},
		pages: "page_sections",
		about_choose_use: "home/about_choose_us",
		top_service: {
			top_services: "home/top_service",
			top_service_single: "home/service/:id",
		},
		header: "header",
		settings: "settings",
		counter: "counter",

		blog: {
			categories: "list/blog_category",
			blogs: "blogs",
			blog_single: "blogs/:id",
			// blogs_latest: "customer/blogscategorie/latesblog",
		},
		branch: {
			branches: "customer/branchs",
			branch_single: "customer/branchs/:id",
			// visiting: "customer/branchs/visiting",
			doctor: "customer/appointment/doctor",
			customer_appointment: "patient/appointment",
			delete_appointment: "patient/appointment/",
			edit_appointment: "patient/appointment/",
			visiting_single: "customer/branchs/visiting/:id",
			visiting_by_doctor: "customer/branchs/visiting/doctors/:id",
			visiting: "branch/visiting",
			own: "branch/own",
		},
		appointment: {
			myappointment: "patient/appointment",
			displayAppointmentsTv: "admin/appointment/display",
			patientFile: "patient/prescription",
		},
	},
	patient: {
		login: "patient/login",
		patientDashboard: "/patient/dashboard",
		register: "register",
		generate_otp: "/patient/otp",
		verify_otp: "/patient/verify",
		patient_profile: "/patient/profile",
		user: {
			get_users: "customer/register",
		},
		select_doctor: "patient/appointment/doctor_time",
		references: "/private/list/branch", //
		add_review: "testimonial",
		add_contact: "inquiries",
		forgot_password: {
			confirm_email: "customer/register/reset-password",
			confirm_otp: "customer/register/confirm-otp",
			confirm_reset_password: "customer/register/confirm-reset-password",
		},
	},
	admin: {
		get_staff_permission: "/private/user/",
		add_staff_permission: "/private/user",

		get_vendor_login: "/sa/vendor/get_token/",

		get_permission_by_id: "/sa/user/permissions/",
		admin_dashboard: {
			patient_by_department: "/private/dashboard/admin/patient_by_department",
			patient_by_gender: "/private/dashboard/admin/patient_by_gender",
			top_department: "/private/dashboard/admin/get_top_departments",
			summary: "/private/dashboard/admin/summary",
		},
		doctor_dashboard: {
			summary: "private/dashboard/doctor/summary",
			income: "/private/dashboard/doctor/income",
			patient_by_gender: "/private/dashboard/doctor/gender",
		},

		// vendor
		get_vendor: "user_vendor",
		set_vendor_id: "user_vendor/:id",
		get_vendor_user: "/sa/user",
		create_vendor_user: "/sa/user",
		edit_vendor_user: "/sa/user/:id",
		delete_vendor_user: "/sa/user/",

		get_staff: "private/profile/staff",
		edit_staff: "private/profile/staff",

		login: "private/login", // admin login,
		reset_password: "private/change_password",
		staff_reset_password: "private/staff/reset_password/",
		doctor_reset_password: "private/doctor/reset_password/",
		confirm_otp: "admin/confirm-otp",
		services: "private/service",
		symptom: "private/symptom",
		add_services: "private/service",
		edit_services: "private/service/:id",
		delete_services: "private/service/",
		confirm_reset_paasword: "admin/confirm-reset-password",
		cancle_appointment: "/private/appointment/canceled/",

		retransfer_appointment: "/private/appointment/re_transfer",
		transfer_doctor_appointment: "/private/appointment/transfer",
		get_doctor_data: "/private/appointment/doctor_time",

		testimonial: "private/testimonial",
		add_testimonial: "private/testimonial",
		edit_testimonial: "private/testimonial/:id",
		delete_testimonial: "private/testimonial/",

		blog_category_pagination: "/private/blog_categories",
		add_blog_category: "private/blog_categories",
		edit_blog_category: "private/blog_categories/:id",
		delete_blog_category: "private/blog_categories/",

		blog_comment: "/private/blog_comment",
		toggle_blog_comment: "/private/blog_comment/toggle/",

		snooze_appointment: "/private/appointment/snooze/",

		blog: "/private/blogs",
		add_blog: "/private/blogs",
		edit_blog: "/private/blogs/:id",
		delete_blog: "/private/blogs/",
		get_blog_by_id: "private/blogs/",
		blog_category: "private/blog_categories",

		blog_category_list: "/private/list/blog_category",

		list_page: "/private/list/pages", //

		get_patient: "private/patient",
		view_patient: "/private/patient",
		edit_patient: "/private/patient/:id",
		delete_patient: "/private/patient/",

		department: "/private/departments",
		list_department: "/private/list/department", //
		add_departments: "private/departments",
		edit_departments: "private/departments/:id",
		delete_departments: "private/departments/",

		holiday: "/private/holiday",
		add_holiday: "private/holiday",
		edit_holiday: "private/holiday/:id",
		delete_holiday: "private/holiday/",

		patient_extra_detail: "private/appointment/details/",

		counter: "private/counter",
		add_counter: "private/counter",
		edit_counter: "private/counter/:id",
		delete_counter: "private/counter/",

		setting: "private/settings",
		edit_setting: "private/settings",

		update_position: "/private/prescription/position",

		enquiry: "private/inquiries",
		delete_enquiry: "private/inquiries/",

		branch: "private/branch",

		branch: "private/branch",
		list_branch: "/private/list/branch", //

		get_branch_by_id: "private/branch/",
		add_branch: "private/branch",
		edit_branch: "private/branch/:id",
		delete_branch: "private/branch/",

		branch_time_schedule: "private/time/",
		add_branch_timetable: "private/time",
		edit_branch_timetable: "private/time/:id",
		delete_branch_timetable: "private/time/",

		choose_us: "private/choose_us",
		add_choose_us: "private/choose_us",
		edit_choose_us: "private/choose_us/:id",
		delete_choose_us: "private/choose_us/",

		page_section: "private/page_section",
		get_page_section_by_id: "private/page_section/",
		add_page_section: "private/page_section",
		edit_page_section: "private/page_section/:id",
		delete_page_section: "private/page_section/",

		socket_testing: "/socket",

		get_pages: "private/pages",

		get_user: "/private/staff",
		add_user: "private/staff",
		edit_user: "private/staff/:id",
		delete_user: "private/staff/",
		resign_staff: "private/staff/resign",
		restore_staff: "private/staff/resign/restore/",

		// Experience Letter

		staff_experience_letter: "private/letter_data/staff/experience",
		doctor_experience_letter: "private/letter_data/doctor/experience",
		intern_experience_letter: "private/letter_data/intern/experience",

		// letter History
		get_letter_history_data: "private/letter_history",
		letterHistoryGenerateLetter: "/private/letter_history",
		letterHistoryById: "private/letter_history/id",

		branches: "admin/branch",

		sliders: "private/sliders",
		add_sliders: "private/sliders",
		edit_sliders: "private/sliders/:id",
		delete_sliders: "private/sliders/",

		header: "private/header/nested",
		add_header: "private/header",
		edit_header: "private/header/:id",
		toggle_header: "/private/header/toggle/:id",
		delete_header: "private/header/",
		change_header_menus: "/private/header/bulk",

		gallery: "private/gallery",
		add_gallery: "private/gallery",
		edit_gallery: "private/gallery/:id",
		delete_gallery: "private/gallery/",

		departments: "private/departments",
		view_all_permissions: "private/permissions",

		vendor_permission: "/private/permissions/sa?grouped=",
		get_all_staff_permission: "/private/permissions/vendor?grouped=",
		edit_user_permission: "/sa/user/permissions",

		role: "sa/role",
		get_role_by_id: "sa/role/",
		add_role: "sa/role",
		edit_role: "sa/role",
		role_pagination: "sa/role",
		delete_role_pagination: "sa/role/",

		get_refrence: "private/reference",
		create_refrence: "private/reference",
		edit_refrence: "private/reference/:id",
		delete_refrence: "private/reference/",
		list_refrence: "/private/list/reference",

		list_doctor: "/private/list/doctor",

		get_doctors: "/private/doctor",
		get_intern_doctor: "/private/doctor/intern",

		resign_doctor: "/private/doctor/resign/intern",
		restore_resign_doctor: "private/doctor/resign/restore/doctor/",

		get_doctor_today_appointment: "private/appointment/today",
		get_doctor_previousdata: "admin/doctors/previousdata/",
		edit_doctor_appointment: "admin/doctors/appointment/active/",
		get_prescription_canvas_image: "private/prescription/prescription_image/",
		add_prescription_image: "admin/doctors/prescription/",
		add_doctor_holiday: "admin/doctors/holiday",
		get_doctor_branch: "admin/doctors/branch",
		get_doctor_branch_id: "admin/doctors/login-branch-id",
		get_available_doctor: "admin/doctors/todaydoctor",
		add_edit_doctor_time: "/private/doctor_time/bulk",
		get_doctor_time: "/private/doctor_time",

		restore_intern_doctor: "/private/doctor/resign/restore/intern/",
		resign_main_doctor: "/private/doctor/resign/doctor/",

		get_appointment: "private/appointment",
		get_appointment_by_id: "private/appointment/",

		reschedule_appointment: "/private/appointment/reschedule",

		create_symptom: "/private/symptom",
		edit_symptom: "/private/symptom/:id",
		delete_symptom: "/private/symptom/",
		list_symptom: "/private/list/symptom",

		// get_appointment: "admin/appointment/date",
		delete_appointment: "private/appointment/",
		change_weight: "private/appointment/weight/",
		edit_appointment: "private/appointment/",
		search_appointment: "admin/appointment/mobile",

		create_appointment: "/private/patient",
		select_doctor: "/private/doctor_time",
		add_appointment: "private/appointment",

		create_prescription: "/private/prescription",

		search_name: "admin/appointment/search",
		change_appointment_time: "admin/appointment/sanuz/",
		display_tv_appointments: "admin/appointment/display",

		add_edit_and_get_blogImages: "private/blog_image/",
		delete_blogImages: "private/blog_image/",
		delete_prescriptionImage: "private/prescription/",

		change_appointment_to_complete: "/private/appointment/complete/",
		change_appointment_to_on_going: "/private/appointment/on_going/",
		change_appointment_status_to_call: "/private/appointment/call/",
		get_prescription_image: "/private/prescription/",
		get_prescription_image_by_patient: "/private/prescription",

		enquiry_bulk_read_or_unread: "/private/inquiries/toggle",

		list_appointment: "/private/list/appointment",

		create_doctor: "private/doctor",
		add_doctor: "private/doctor",
		edit_doctor: "private/doctor/:id",
		delete_doctor: "private/doctor/",
		get_doctor_by_id: "private/doctor/",
		add_certificate: "private/certificates/",
		get_certificate: "private/certificates",
		edit_certificate: "private/certificates/",
		get_certificate_by_id: "private/certificates",
		delete_certificate: "private/certificates/",

		get_doctor_visit_time: "/private/doctor_visit_time/",
		add_doctor_visit_time: "private/doctor_visit_time",
		edit_doctor_visit_time: "private/doctor_visit_time/:id",
		delete_doctor_visit_time: "private/doctor_visit_time/",

		add_doctor_profile_visit_time: "private/profile/doctor/doctor_visit_time",
		edit_doctor_profile_visit_time: "private/profile/doctor/doctor_visit_time/:id",
		delete_doctor_profile_visit_time: "private/profile/doctor/doctor_visit_time/",

		new_doctor_appointment: "admin/doctors/appointment/call/",
		active_doctor: "admin/doctors/activedoctor",

		add_patient: "/private/patient",
		edit_patient: "/private/patient/:id",
		list_patient: "/private/list/patient",
		get_patient_appointment: "private/patient/appointments",

		edit_presriptionImage: "private/prescription/",
		create_presriptionImage: "private/prescription",
		doctor_profile: "private/profile/doctor",
		edit_doctor_profile: "private/profile/doctor",
		edit_doctor_profile_certificate: "private/profile/doctor/certificate/",
		add_doctor_profile_certificate: "private/profile/doctor/certificate",
		delete_doctor_profile_certificate: "private/profile/doctor/certificate/",
		doctor_profile_doctor_time: "/private/profile/doctor/doctor_time",
		bulk_doctor_profile_doctor_time: "/private/profile/doctor/doctor_time",
		doctor_profile_visit_time: "/private/profile/doctor/doctor_visit_time",

		// letter formate
		latter_data: "private/letter_data/staff",
		letter_formate: "private/letter_format_content",
		letterFormatCategoryWise: "private/letter_format_content/category-wise-tags",
		delete_later: "/private/letter_format_content/",

		search_doctor_get: "/private/doctor/registered",
		edit_search_doctor: "/private/doctor/register",
	},
	vendor: {
		get_vendor: "/sa/vendor",
		create_vendor: "/sa/vendor",
		edit_vendor: "/sa/vendor/:id",
		is_domain_available: "/sa/vendor/is-domain-available",
		delete_vendor: "/sa/vendor/",
		vender_seed_default: "sa/vendor/seed_defaults/",
	},
	all_permissions: {
		//
		add_appointments: "add_appointments",
		edit_appointments: "edit_appointments",
		get_appointments: "get_appointments",
		delete_appointments: "delete_appointments",
		//
		add_blog_categories: "add_blog_categories",
		edit_blog_categories: "edit_blog_categories",
		get_blog_categories: "get_blog_categories",
		delete_blog_categories: "delete_blog_categories",
		//
		toggle_blog_comments: "toggle_blog_comments",
		edit_blog_comments: "edit_blog_comments",
		delete_blog_comments: "delete_blog_comments",
		//
		add_blogs: "add_blogs",
		edit_blogs: "edit_blogs",
		get_blogs: "get_blogs",
		delete_blogs: "delete_blogs",
		//
		add_branch: "add_branch",
		edit_branch: "edit_branch",
		get_branch: "get_branch",
		delete_branch: "delete_branch",
		//
		add_choose_us: "add_choose_us",
		edit_choose_us: "edit_choose_us",
		get_choose_us: "get_choose_us",
		delete_choose_us: "delete_choose_us",
		//
		add_counter: "add_counter",
		edit_counter: "edit_counter",
		get_counter: "get_counter",
		delete_counter: "delete_counter",
		//
		add_departments: "add_departments",
		edit_departments: "edit_departments",
		get_departments: "get_departments",
		delete_departments: "delete_departments",
		//
		add_doctors: "add_doctors",
		edit_doctors: "edit_doctors",
		get_doctors: "get_doctors",
		delete_doctors: "delete_doctors",
		//
		get_doctor_time: "get_doctor_time",
		get_doctor_visit_time: "get_doctor_visit_time",
		//
		add_galleries: "add_galleries",
		edit_galleries: "edit_galleries",
		get_galleries: "get_galleries",
		delete_galleries: "delete_galleries",
		//
		add_header: "add_header",
		edit_header: "edit_header",
		get_header: "get_header",
		delete_header: "delete_header",
		//
		add_holiday: "add_holiday",
		edit_holiday: "edit_holiday",
		get_holiday: "get_holiday",
		delete_holiday: "delete_holiday",
		//
		add_inquiries: "add_inquiries",
		edit_inquiries: "edit_inquiries",
		get_inquiries: "get_inquiries",
		delete_inquiries: "delete_inquiries",
		//
		add_pages: "add_pages",
		edit_pages: "edit_pages",
		get_pages: "get_pages",
		delete_pages: "delete_pages",
		//
		add_page_sections: "add_page_sections",
		edit_page_sections: "edit_page_sections",
		get_page_sections: "get_page_sections",
		delete_page_sections: "delete_page_sections",
		//
		add_patient: "add_patient",
		edit_patient: "edit_patient",
		get_patient: "get_patient",
		delete_patient: "delete_patient",
		//
		get_permissions: "get_permissions",
		//
		add_prescription: "add_prescription",
		edit_prescription: "edit_prescription",
		get_prescription: "get_prescription",
		delete_prescription: "delete_prescription",
		//
		add_reference: "add_reference",
		edit_reference: "edit_reference",
		get_reference: "get_reference",
		delete_reference: "delete_reference",
		//
		add_role: "add_role",
		edit_role: "edit_role",
		get_role: "get_role",
		//
		add_services: "add_services",
		edit_services: "edit_services",
		get_services: "get_services",
		delete_services: "delete_services",
		//
		edit_settings: "edit_settings",
		get_settings: "get_settings",
		//
		add_sliders: "add_sliders",
		edit_sliders: "edit_sliders",
		get_sliders: "get_sliders",
		delete_sliders: "delete_sliders",
		//
		add_staff: "add_staff",
		edit_staff: "edit_staff",
		get_staff: "get_staff",
		delete_staff: "delete_staff",
		//
		add_testimonial: "add_testimonial",
		edit_testimonial: "edit_testimonial",
		get_testimonial: "get_testimonial",
		delete_testimonial: "delete_testimonial",
		//
		add_time: "add_time",
		edit_time: "edit_time",
		get_time: "get_time",
		delete_time: "delete_time",
		//
		dashboard_admin: "dashboard_admin",
		dashboard_doctor: "dashboard_doctor",
		dashboard_patient: "dashboard_patient",
		//
		add_symptom: "add_symptom",
		edit_symptom: "edit_symptom",
		get_symptom: "get_symptom",
		delete_symptom: "delete_symptom",
		//
		staff_resign: "staff_resign",
		staff_restore_resign: "staff_restore_resign",
		//
		doctor_resign: "doctor_resign",
		doctor_restore_resign: "doctor_restore_resign",
		//
		add_letter_format_content: "add_letter_format_content",
		edit_letter_format_content: "edit_letter_format_content",
		get_letter_format_content: "get_letter_format_content",
		delete_letter_format_content: "delete_letter_format_content",
		//
		letter_generate: "letter_generate",
		//
		add_vendor: "add_vendor",
		seed_vendor: "seed_vendor",
		edit_vendor: "edit_vendor",
		get_vendor: "get_vendor",
		delete_vendor: "delete_vendor",
		//
		add_user: "add_user",
		edit_user: "edit_user",
		get_user: "get_user",
		delete_user: "delete_user",
		//
		view_as_vendor: "view_as_vendor",
	},
};

export default apiConfigNew;
