import { appConfig, pageRoutes } from "@/configs";
import { checkUserPermission } from "@/constant/permissions";
import { helper } from "@/services";
import { RoleName } from "@/services/helper";
import { store } from "@/store";
import { handleLogin } from "@/store/toolkit/auth";
import _ from "lodash";
import { lazy } from "react";

/* *******************************************admin routes import ******************************************* */

const Login = lazy(() => import("../pages/admin/core/login"));
const AdminDashboard = lazy(() => import("../pages/admin/pages/dashboard/AdminDashboard"));
const AdminDoctorDashboard = lazy(() => import("./../pages/admin/pages/dashboard/DoctorDashboard"));
const AdminPatientDashboard = lazy(() => import("./../pages/admin/pages/dashboard/PatientDashboard"));
const AdminService = lazy(() => import("./../pages/admin/pages/service/index"));
const AdminEnquiry = lazy(() => import("./../pages/admin/pages/enquiry"));
const AdminDepartments = lazy(() => import("./../pages/admin/pages/departments"));
const AdminTestimonial = lazy(() => import("./../pages/admin/pages/testimonial/index"));
const AdminBranches = lazy(() => import("./../pages/admin/pages/branches/index"));
const AdminAddBranch = lazy(() => import("../pages/admin/pages/branches/addBranch.jsx"));
const AdminEditBranch = lazy(() => import("../pages/admin/pages/branches/editBranch.jsx"));
const AdminPageSection = lazy(() => import("../pages/admin/pages/pageContent/pageSection/index"));
const AdminEditPageSection = lazy(() => import("../pages/admin/pages/pageContent/pageSection/editPageSection"));
const AdminAddPageSection = lazy(() => import("../pages/admin/pages/pageContent/pageSection/addPageSection"));
const AdminChooseUs = lazy(() => import("../pages/admin/pages/pageContent/chooseUs/index"));
const AdminSetting = lazy(() => import("../pages/admin/pages/setting/index"));
const AdminCounter = lazy(() => import("../pages/admin/pages/counter/index"));
const AdminHoliday = lazy(() => import("../pages/admin/pages/holiday/index"));
const AdminBlogCategory = lazy(() => import("../pages/admin/pages/blog/blogCategory/index"));
const AdminBlog = lazy(() => import("../pages/admin/pages/blog/blog/index"));
const AdminAddBlog = lazy(() => import("../pages/admin/pages/blog/blog/addBlog"));
const AdminEditBlog = lazy(() => import("../pages/admin/pages/blog/blog/editBlog"));
const AdminSliders = lazy(() => import("../pages/admin/pages/home/slider/index"));
const AdminHeaders = lazy(() => import("../pages/admin/pages/home/header/index"));
const AdminGallery = lazy(() => import("../pages/admin/pages/gallery/index"));
const AdminDoctor = lazy(() => import("../pages/admin/pages/doctors/doctors/index"));
const AdminAddDoctor = lazy(() => import("../pages/admin/pages/doctors/doctors/addDoctor"));
const AdminEditDoctor = lazy(() => import("../pages/admin/pages/doctors/doctors/editDoctor"));
const AdminPatient = lazy(() => import("../pages/admin/pages/patients/index"));
const AdminViewPatient = lazy(() => import("../pages/admin/pages/patients/viewPatient"));
const AdminRole = lazy(() => import("../pages/admin/pages/users/role/index"));
const AdminAddRole = lazy(() => import("../pages/admin/pages/users/role/addRole"));
const AdminEditRole = lazy(() => import("../pages/admin/pages/users/role/editRole"));
const AdminPermission = lazy(() => import("../pages/admin/pages/users/permission/index"));
const AdminSatff = lazy(() => import("../pages/admin/pages/users/staff/index"));
const AdminAppointment = lazy(() => import("../pages/admin/pages/appointment/index"));
const AdminAppointmentMonitor = lazy(() => import("../pages/admin/appointmentMonitor"));
const AdminDoctorTodayAppointment = lazy(() => import("../pages/admin/pages/appointment/todayDoctorAppointment"));
const AdminCreateAppointment = lazy(() => import("../pages/admin/pages/appointment/createAndSearchAppointment/CreateAppointment"));
const AdminTimeSchedule = lazy(() => import("../pages/admin/pages/timeschedule/index"));
const AdminReference = lazy(() => import("../pages/admin/pages/reference/index"));
const AdminSymptom = lazy(() => import("../pages/admin/pages/symptom/index"));
const Profile = lazy(() => import("../pages/admin/pages/profile/index"));
const InternDoctor = lazy(() => import("../pages/admin/pages/profile/ViewInternDoctor"));
const LetterPad = lazy(() => import("../pages/admin/pages/letterPad/LetterPad"));
const LetterHistory = lazy(() => import("../pages/admin/pages/letterHisory"));
const GlobalDoctorSearch = lazy(() => import("../pages/admin/pages/doctors/GlobalDoctorSearch/index"));
const StaffPermission = lazy(() => import("../pages/admin/pages/users/staff/staffPermission"));

/* ******************************************* admin routes import ******************************************* */

/* ******************************************* prescription routes import ******************************************* */

const PrescriptionAppointments = lazy(() => import("./../pages/staff/appointment/AppointmentTable"));
const ViewPatient = lazy(() => import("./../pages/staff/appointment/prescription/index"));
const ViewPrescription = lazy(() => import("./../pages/staff/appointment/prescription/viewPatient"));
const StaffProfile = lazy(() => import("./../pages/staff/staffProfile"));

/* ******************************************* vendor routes import ******************************************* */

const Vendors = lazy(() => import("./../pages/vendor/index"));
const UserVendor = lazy(() => import("./../pages/vendor/user/index"));
const UserPermission = lazy(() => import("./../pages/vendor/user/userPermission"));

/* ******************************************* patient routes import ******************************************* */

const PatientAppointments = lazy(() => import("./../pages/patient/pages/appointment/index"));
const PatientFiles = lazy(() => import("./../pages/patient/pages/patient-file/index"));
const PatientProfile = lazy(() => import("./../pages/patient/pages/patient-profile/index"));

export const webRoutes = {
	admin: [
		{
			path: pageRoutes.admin.profile,
			element: Profile,
		},
		{
			path: pageRoutes.admin.home,
			element: AdminDashboard,
		},
		{
			path: pageRoutes.admin.symptom,
			element: AdminSymptom,
		},
		{
			path: pageRoutes.admin.dashboard_doctor,
			element: AdminDoctorDashboard,
		},
		{
			path: pageRoutes.admin.services,
			element: AdminService,
		},
		{
			path: pageRoutes.admin.testimonial,
			element: AdminTestimonial,
		},
		{
			path: pageRoutes.admin.branches,
			element: AdminBranches,
		},
		{
			path: pageRoutes.admin.addBranch,
			element: AdminAddBranch,
		},
		{
			path: pageRoutes.admin.editBranch,
			element: AdminEditBranch,
		},
		{
			path: pageRoutes.admin.enquiry,
			element: AdminEnquiry,
		},
		{
			path: pageRoutes.admin.departments,
			element: AdminDepartments,
		},
		{
			path: pageRoutes.admin.pagesSection,
			element: AdminPageSection,
		},
		{
			path: pageRoutes.admin.addPagesSection,
			element: AdminAddPageSection,
		},
		{
			path: pageRoutes.admin.editPagesSection,
			element: AdminEditPageSection,
		},
		{
			path: pageRoutes.admin.chooseUs,
			element: AdminChooseUs,
		},
		{
			path: pageRoutes.admin.setting,
			element: AdminSetting,
		},
		{
			path: pageRoutes.admin.counter,
			element: AdminCounter,
		},
		{
			path: pageRoutes.admin.holiday,
			element: AdminHoliday,
		},
		{
			path: pageRoutes.admin.blogCategory,
			element: AdminBlogCategory,
		},
		{
			path: pageRoutes.admin.blog,
			element: AdminBlog,
		},
		{
			path: pageRoutes.admin.addBlog,
			element: AdminAddBlog,
		},
		{
			path: pageRoutes.admin.editBlog,
			element: AdminEditBlog,
		},
		{
			path: pageRoutes.admin.sliders,
			element: AdminSliders,
		},
		{
			path: pageRoutes.admin.headers,
			element: AdminHeaders,
		},
		{
			path: pageRoutes.admin.gallery,
			element: AdminGallery,
		},
		{
			path: pageRoutes.admin.doctors,
			element: AdminDoctor,
		},
		{
			path: pageRoutes.admin.addDoctors,
			element: AdminAddDoctor,
		},
		{
			path: pageRoutes.admin.editDoctors,
			element: AdminEditDoctor,
		},
		{
			path: pageRoutes.admin.all_patients,
			element: AdminPatient,
		},
		{
			path: pageRoutes.admin.view_patient_detail,
			element: AdminViewPatient,
		},

		{
			path: pageRoutes.admin.staff,
			element: AdminSatff,
		},
		{
			path: pageRoutes.admin.appointment,
			element: AdminAppointment,
		},
		{
			path: pageRoutes.admin.today_doctor_appointment,
			element: AdminDoctorTodayAppointment,
		},
		{
			path: pageRoutes.admin.appointmentMonitor,
			element: AdminAppointmentMonitor,
		},
		{
			path: pageRoutes.admin.create_appointment,
			element: AdminCreateAppointment,
		},
		{
			path: pageRoutes.admin.edit_appointment,
			element: AdminCreateAppointment,
		},
		{
			path: pageRoutes.admin.time_schedule,
			element: AdminTimeSchedule,
		},
		{
			path: pageRoutes.admin.reference,
			element: AdminReference,
		},
		{
			path: pageRoutes.admin.intern_doctor,
			element: InternDoctor,
		},
		{
			path: pageRoutes.admin.letter_format_content,
			element: LetterPad,
		},
		{
			path: pageRoutes.admin.letter_generate,
			element: LetterHistory,
		},
		{
			path: pageRoutes.admin.global_doctor_search,
			element: GlobalDoctorSearch,
		},
		{
			path: pageRoutes.admin.staff_permission,
			element: StaffPermission,
		},
	],

	guest: [
		{
			path: pageRoutes.admin.login,
			element: Login,
		},
		{
			path: pageRoutes.patient.patient_login,
			element: Login,
		},
	],

	patient: [
		{
			path: pageRoutes.patient.patient_dashboard,
			element: AdminPatientDashboard,
		},
		{
			path: pageRoutes.patient.appointments,
			element: PatientAppointments,
		},
		{
			path: pageRoutes.patient.patient_files,
			element: PatientFiles,
		},
		{
			path: pageRoutes.patient.patient_profile,
			element: PatientProfile,
		},
	],

	prescription: [
		{
			path: pageRoutes.prescription.appointment,
			element: PrescriptionAppointments,
		},
		{
			path: pageRoutes.prescription.patient,
			element: ViewPatient,
		},

		{
			path: pageRoutes.prescription.prescription,
			element: ViewPrescription,
		},
		{
			path: pageRoutes.prescription.profile,
			element: StaffProfile,
		},
	],
	vender: [
		{
			path: pageRoutes.vendor.vendors,
			element: Vendors,
		},
		{
			path: pageRoutes.vendor.role,
			element: AdminRole,
		},
		{
			path: pageRoutes.vendor.addRole,
			element: AdminAddRole,
		},
		{
			path: pageRoutes.vendor.editRole,
			element: AdminEditRole,
		},
		{
			path: pageRoutes.vendor.permission,
			element: AdminPermission,
		},
		{
			path: pageRoutes.vendor.user,
			element: UserVendor,
		},
		{
			path: pageRoutes.vendor.user_permission,
			element: UserPermission,
		},
	],
	getAdminRoutes: function () {
		const { filter_page_data = [] } = checkUserPermission();
		return filter_page_data;
	},
};
