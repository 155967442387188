import { apiConfigNew, pageRoutes } from "@/configs";
import { webRoutes } from "@/routes/webRoutes";
import { userPermissions } from "@/services/helper";
import { getAuthState } from "@/store/selector";
import { adminMenus } from "./menus";
import { helper } from "@/services";
import { useNavigate } from "react-router-dom";

export const checkUserPermission = () => {
	let _adminMenus = JSON.parse(JSON.stringify(adminMenus));
	const get_role = {
		admin: "admin",
		doctor: "admin",
		staff: "admin",
		prescription: "prescription",
		patient: "patient",
		super_admin: "vender",
	};
	const userData = getAuthState();
	const permissions = userPermissions();

	const _permission = {
		//all_dashboard
		[pageRoutes.admin.home]: [permissions?.dashboard_admin == apiConfigNew.all_permissions.dashboard_admin],
		[pageRoutes.admin.dashboard_doctor]: [permissions?.dashboard_doctor == apiConfigNew.all_permissions.dashboard_doctor],
		[pageRoutes.patient.patient_dashboard]: [permissions?.dashboard_patient == apiConfigNew.all_permissions.dashboard_patient],

		//get_slider
		[pageRoutes.admin.sliders]: [permissions?.get_sliders == apiConfigNew.all_permissions.get_sliders],

		//get_header
		[pageRoutes.admin.headers]: [permissions?.get_header == apiConfigNew.all_permissions.get_header],

		//get_services
		[pageRoutes.admin.services]: [permissions?.get_services == apiConfigNew.all_permissions.get_services],

		//get_department
		[pageRoutes.admin.departments]: [permissions?.get_departments == apiConfigNew.all_permissions.get_departments],

		//doctor all_permission
		[pageRoutes.admin.doctors]: [permissions?.get_doctors == apiConfigNew.all_permissions.get_doctors],
		[pageRoutes.admin.addDoctors]: [permissions?.add_doctors == apiConfigNew.all_permissions.add_doctors],
		[pageRoutes.admin.editDoctors]: [permissions?.edit_doctors == apiConfigNew.all_permissions.edit_doctors],

		//get doctor_global_search
		[pageRoutes.admin.global_doctor_search]: [permissions?.get_doctors == apiConfigNew.all_permissions.get_doctors],

		//get_gallery
		[pageRoutes.admin.gallery]: [permissions?.get_galleries == apiConfigNew.all_permissions.get_galleries],

		//get_blog_category
		[pageRoutes.admin.blogCategory]: [permissions?.get_blog_categories == apiConfigNew.all_permissions.get_blog_categories],

		//get_blog_all_permission
		[pageRoutes.admin.blog]: [permissions?.get_blogs == apiConfigNew.all_permissions.get_blogs],
		[pageRoutes.admin.addBlog]: [permissions?.add_blogs == apiConfigNew.all_permissions.add_blogs],
		[pageRoutes.admin.editBlog]: [permissions?.edit_blogs == apiConfigNew.all_permissions.edit_blogs],

		//get_enquiry
		[pageRoutes.admin.enquiry]: [permissions?.get_inquiries == apiConfigNew.all_permissions.get_inquiries],

		//get_testimonial
		[pageRoutes.admin.testimonial]: [permissions?.get_testimonial == apiConfigNew.all_permissions.get_testimonial],

		//get_branches
		[pageRoutes.admin.branches]: [permissions?.get_branch == apiConfigNew.all_permissions.get_branch],
		[pageRoutes.admin.addBranch]: [permissions?.add_branch == apiConfigNew.all_permissions.add_branch],
		[pageRoutes.admin.editBranch]: [permissions?.edit_branch == apiConfigNew.all_permissions.edit_branch],
		[pageRoutes.admin.time_schedule]: [permissions?.get_time == apiConfigNew.all_permissions.get_time],

		//get_page_section
		[pageRoutes.admin.pagesSection]: [permissions?.get_page_sections == apiConfigNew.all_permissions.get_page_sections],
		[pageRoutes.admin.addPagesSection]: [permissions?.add_page_sections == apiConfigNew.all_permissions.add_page_sections],
		[pageRoutes.admin.editPagesSection]: [permissions?.edit_page_sections == apiConfigNew.all_permissions.edit_page_sections],

		//get_choose_us
		[pageRoutes.admin.chooseUs]: [permissions?.get_choose_us == apiConfigNew.all_permissions.get_choose_us],

		//get_roles
		[pageRoutes.vendor.role]: [permissions?.get_role == apiConfigNew.all_permissions.get_role],
		[pageRoutes.vendor.addRole]: [permissions?.add_role == apiConfigNew.all_permissions.add_role],
		[pageRoutes.vendor.editRole]: [permissions?.edit_role == apiConfigNew.all_permissions.edit_role],

		//get_permission
		[pageRoutes.vendor.permission]: [permissions?.get_permissions == apiConfigNew.all_permissions.get_permissions],

		//get_staff_permission
		[pageRoutes.admin.staff]: [permissions?.get_staff == apiConfigNew.all_permissions.get_staff],
		[pageRoutes.admin.staff_permission]: [permissions?.get_staff == apiConfigNew.all_permissions.get_staff],

		//get_reference
		[pageRoutes.admin.reference]: [permissions?.get_reference == apiConfigNew.all_permissions.get_reference],

		//get_symptom
		[pageRoutes.admin.symptom]: [permissions?.get_symptom == apiConfigNew.all_permissions.get_symptom],

		//get_setting
		[pageRoutes.admin.setting]: [permissions?.get_settings == apiConfigNew.all_permissions.get_settings],

		//get_counter
		[pageRoutes.admin.counter]: [permissions?.get_counter == apiConfigNew.all_permissions.get_counter],

		//get_holiday
		[pageRoutes.admin.holiday]: [permissions?.get_holiday == apiConfigNew.all_permissions.get_holiday],

		//get_latter_pad
		[pageRoutes.admin.letter_format_content]: [permissions?.get_letter_format_content == apiConfigNew.all_permissions.get_letter_format_content],

		//get_latter_history
		[pageRoutes.admin.letter_generate]: [permissions?.letter_generate == apiConfigNew.all_permissions.letter_generate],

		//get_display_tv
		[pageRoutes.admin.appointmentMonitor]: [["staff", "admin"].includes(userData?.user?.role_name)],

		//get_intern
		[pageRoutes.admin.intern_doctor]: [userData?.doctor?.is_intern == false],

		//get_patient
		[pageRoutes.admin.all_patients]: [
			permissions?.get_patient == apiConfigNew.all_permissions.get_patient && !["super_admin", "patient"].includes(userData?.user?.role_name),
		],
		[pageRoutes.admin.view_patient_detail]: [
			(permissions?.get_patient == apiConfigNew.all_permissions.get_patient ||
				permissions?.get_appointments == apiConfigNew.all_permissions.get_appointments ||
				permissions?.get_prescription == apiConfigNew.all_permissions.get_prescription) &&
				!["super_admin", "patient"].includes(userData?.user?.role_name),
		],

		//get_vendors
		[pageRoutes.vendor.vendors]: [permissions?.get_vendor == apiConfigNew.all_permissions.get_vendor],

		//get_user
		[pageRoutes.vendor.user_permission]: [permissions?.get_user == apiConfigNew.all_permissions.get_user],
		[pageRoutes.vendor.user]: [permissions?.get_user == apiConfigNew.all_permissions.get_user],

		//patient_all_permissions
		[pageRoutes.patient.appointments]: [permissions?.get_appointments && userData?.user?.role_name == "patient"],
		[pageRoutes.patient.patient_profile]: [userData?.user?.role_name == "patient"],
		[pageRoutes.patient.patient_files]: [permissions?.get_prescription && userData?.user?.role_name == "patient"],

		//doctor_profile_page
		[pageRoutes.admin.profile]: [["staff", "doctor"].includes(userData?.user?.role_name)],

		//get_all_appointment_permission
		[pageRoutes.admin.appointment]: [
			permissions?.get_appointments == apiConfigNew.all_permissions.get_appointments && userData?.user?.role_name !== "patient",
		],
		[pageRoutes.admin.create_appointment]: [
			permissions?.add_appointments == apiConfigNew.all_permissions.add_appointments && userData?.user?.role_name !== "patient",
		],
		[pageRoutes.admin.edit_appointment]: [
			permissions?.edit_appointments == apiConfigNew.all_permissions.edit_appointments && userData?.user?.role_name !== "patient",
		],
		[pageRoutes.admin.today_doctor_appointment]: [userData?.user?.role_name == "doctor"],

		//prescription_patient
		[pageRoutes.prescription.appointment]: [userData?.user?.role_name == "prescription"],
		[pageRoutes.prescription.prescription]: [userData?.user?.role_name == "prescription"],
		[pageRoutes.prescription.patient]: [userData?.user?.role_name == "prescription"],
		[pageRoutes.prescription.profile]: [userData?.user?.role_name == "prescription"],
	};
	const filter_page_data = webRoutes?.[get_role[userData?.user?.role_name]]?.filter((route) => _permission[route.path]?.includes(true));
	const filter_route_data = _adminMenus.filter((menu) => {
		if (menu.link == "/#" && helper.isDefined(menu.subItems)) {
			menu.subItems = menu.subItems.filter((subMeny) => {
				return _permission[subMeny.link]?.includes(true);
			});
			return menu.subItems.length > 0;
		} else {
			return _permission[menu.link]?.includes(true);
		}
	});

	return {
		filter_page_data,
		filter_route_data,
	};
};
