import React from "react";
import { Button, Spinner } from "react-bootstrap";

const ReactButton = ({ variant, size, children, style, onClick, loader, disabled, className, ...rest }) => {
	return (
		<Button disabled={disabled} variant={variant} onClick={onClick} size={size} style={style} {...rest} className={`${className}`}>
			{loader ? <Spinner size="sm" className="me-2"></Spinner> : null}
			{children}
		</Button>
	);
};

export default ReactButton;
