import { pageRoutes } from "@/configs";
import ResetPasswordModal from "@/pages/admin/core/login/partial/ResetPasswordModal";
import { store } from "@/store";
import { handleLogout } from "@/store/toolkit/auth";
import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const BottomNavigation = () => {
	const navigate = useNavigate();
	const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);

	const [menuOpen, setMenuOpen] = useState(false);
	const menuRef = useRef(null);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setMenuOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	return (
		<>
			<div className="bottom-navigation position-fixed bottom-0 bg-white py-3 w-100 px-4">
				<ul className="list-unstyled mb-0 d-flex justify-content-between">
					<li>
						<Link
							to={pageRoutes.prescription.patient}
							className="fontLM lh-1"
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}
						>
							<Icon icon="material-symbols:recent-patient-outline" className="" />
						</Link>
					</li>
					<li>
						<Link
							to={pageRoutes.prescription.appointment}
							className="fontLM lh-1"
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}
						>
							<Icon icon="icon-park-outline:appointment" />
						</Link>
					</li>
					<li>
						<Link
							to={pageRoutes.prescription.profile}
							className="fontLM lh-1"
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}
						>
							<Icon icon="iconamoon:profile-circle-fill" />
						</Link>
					</li>

					<li ref={menuRef} className="position-relative">
						<Link
							onClick={() => {
								toggleMenu(), window.scrollTo({ top: 0, behavior: "smooth" });
							}}
							className="font-20 lh-1"
						>
							<Icon icon="fluent:settings-16-filled" />
						</Link>
						{menuOpen && (
							<div className="menu position-absolute bg-white shadow p-3" style={{ bottom: "45px", right: "-8px" }}>
								<ul className="list-unstyled mb-0">
									<li>
										<button onClick={() => setResetPasswordModalShow(true)} className="font-16 lh-1 d-block mb-3 d-flex align-items-center border-0 ">
											<Icon icon="mdi:lock-reset" className="d-block me-2 fs-20" />
											Change Password
										</button>
									</li>
									<li>
										<button
											onClick={() => {
												navigate(pageRoutes.admin.login);
												store.dispatch(handleLogout());

												// Cookies.remove("X18ZM4ZIMV", { domain: appConfig.domain });
											}}
											className="color font-16 lh-1 d-block d-flex align-items-center border-0 bg-transparent px-0"
										>
											<Icon icon="ic:round-logout" className="d-block me-2" />
											Logout
										</button>
									</li>
								</ul>
							</div>
						)}
					</li>
				</ul>
			</div>
			{/* RESET--PASSWORD--MODAL--START */}
			{resetPasswordModalShow && <ResetPasswordModal setModalShow={setResetPasswordModalShow} modalShow={resetPasswordModalShow} />}
			{/* RESET--PASSWORD--MODAL--END */}
		</>
	);
};

export default BottomNavigation;
