import { appConfig, pageRoutes } from "@/configs";
import { useSelector } from "react-redux";

export const breadCrumbs = {
	[pageRoutes.admin.home]: [{ name: "Dashboard", path: pageRoutes.admin.home, isActive: true }],
	[pageRoutes.admin.dashboard_doctor]: [{ name: "Dashboard", path: pageRoutes.admin.dashboard_doctor, isActive: true }],
	[pageRoutes.patient.patient_dashboard]: [{ name: "Dashboard", path: pageRoutes.patient.patient_dashboard, isActive: true }],
	[pageRoutes.admin.services]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Services", path: pageRoutes.admin.services, isActive: true },
	],
	[pageRoutes.admin.departments]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: true },
		{ name: "Department", path: pageRoutes.admin.departments, isActive: false },
	],
	[pageRoutes.admin.enquiry]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Enquiry", path: pageRoutes.admin.enquiry, isActive: true },
	],
	[pageRoutes.admin.testimonial]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Testimonial", path: pageRoutes.admin.testimonial, isActive: true },
	],
	[pageRoutes.admin.branches]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Branches", path: pageRoutes.admin.branches, isActive: true },
	],
	[pageRoutes.admin.addBranch]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: true },
		{ name: "Branches", path: pageRoutes.admin.branches, isActive: false },
		{ name: "Add Branches", path: pageRoutes.admin.addBranch, isActive: false },
	],
	edit_branches: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: true },
		{ name: "Branches", path: pageRoutes.admin.branches, isActive: false },
		{ name: "Edit Branches", path: pageRoutes.admin.editBranch, isActive: false },
	],
	[pageRoutes.admin.departments]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Departments", path: pageRoutes.admin.departments, isActive: true },
	],
	[pageRoutes.admin.pagesSection]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Page Section", path: pageRoutes.admin.pagesSection, isActive: true },
	],
	[pageRoutes.admin.addPagesSection]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Page Section", path: pageRoutes.admin.pagesSection, isActive: true },
		{ name: "Add Page Section", path: pageRoutes.admin.addPagesSection, isActive: true },
	],
	edit_page_section: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: true },
		{ name: "Page Section", path: pageRoutes.admin.pagesSection, isActive: false },
		{ name: "Edit Page Section", path: pageRoutes.admin.editPagesSection, isActive: false },
	],
	[pageRoutes.admin.chooseUs]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Choose Us", path: pageRoutes.admin.chooseUs, isActive: true },
	],
	[pageRoutes.admin.setting]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Setting", path: pageRoutes.admin.setting, isActive: true },
	],
	[pageRoutes.admin.counter]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Counter", path: pageRoutes.admin.counter, isActive: true },
	],
	[pageRoutes.admin.holiday]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Holiday", path: pageRoutes.admin.holiday, isActive: true },
	],
	[pageRoutes.admin.blogCategory]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Blog Category", path: pageRoutes.admin.blogCategory, isActive: true },
	],
	[pageRoutes.admin.blog]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Blog", path: pageRoutes.admin.blog, isActive: true },
	],
	[pageRoutes.admin.addBlog]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Blog", path: pageRoutes.admin.blog, isActive: true },
		{ name: "Add Blog", path: pageRoutes.admin.addBlog, isActive: true },
	],
	edit_blog: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Blog", path: pageRoutes.admin.blog, isActive: true },
		{ name: "Edit Blog", path: pageRoutes.admin.editBlog, isActive: true },
	],
	[pageRoutes.admin.sliders]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Sliders", path: pageRoutes.admin.sliders, isActive: true },
	],
	[pageRoutes.admin.headers]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Customer Header", path: pageRoutes.admin.headers, isActive: true },
	],
	[pageRoutes.admin.gallery]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Gallery", path: pageRoutes.admin.gallery, isActive: true },
	],
	[pageRoutes.admin.doctors]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Doctors", path: pageRoutes.admin.doctors, isActive: true },
	],
	[pageRoutes.admin.addDoctors]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Doctors", path: pageRoutes.admin.doctors, isActive: false },
		{ name: "add doctors", path: pageRoutes.admin.addDoctors, isActive: true },
	],
	[pageRoutes.admin.global_doctor_search]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Search-Doctors", path: pageRoutes.admin.global_doctor_search, isActive: true },
	],
	edit_doctor: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: true },
		{ name: "Doctors", path: pageRoutes.admin.doctors, isActive: false },
		{ name: "Edit doctors", path: pageRoutes.admin.editDoctors, isActive: false },
	],
	[pageRoutes.admin.all_patients]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Patients", path: pageRoutes.admin.all_patients, isActive: true },
	],
	view_detail: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Patients", path: pageRoutes.admin.all_patients, isActive: true },
		{ name: "View Patient", path: pageRoutes.admin.view_patient_detail, isActive: true },
	],
	[pageRoutes.vendor.role]: [{ name: "Role", path: pageRoutes.vendor.role, isActive: true }],
	[pageRoutes.vendor.addRole]: [
		{ name: "Role", path: pageRoutes.vendor.role, isActive: false },
		{ name: "Add Role", path: pageRoutes.vendor.addRole, isActive: false },
	],
	edit_role: [
		{ name: "Role", path: pageRoutes.vendor.role, isActive: false },
		{ name: "Edit Role", path: pageRoutes.vendor.editRole, isActive: false },
	],
	[pageRoutes.vendor.permission]: [{ name: "Permission", path: pageRoutes.vendor.permission, isActive: true }],
	[pageRoutes.admin.staff]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Staff", path: pageRoutes.admin.staff, isActive: true },
	],
	[pageRoutes.admin.staff_permission]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Staff", path: pageRoutes.admin.staff, isActive: true },
		{ name: "Staff Permission", path: pageRoutes.admin.staff_permission, isActive: true },
	],
	[pageRoutes.admin.appointment]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Appointment", path: pageRoutes.admin.appointment, isActive: true },
	],
	[pageRoutes.admin.today_doctor_appointment]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Appointment", path: pageRoutes.admin.today_doctor_appointment, isActive: true },
	],
	[pageRoutes.admin.search_appointment]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Appointment", path: pageRoutes.admin.appointment, isActive: false },
		{ name: "Search Appointment", path: pageRoutes.admin.search_appointment, isActive: true },
	],
	[pageRoutes.admin.create_appointment]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Appointment", path: pageRoutes.admin.appointment, isActive: false },
		{ name: "Create Appointment", path: pageRoutes.admin.create_appointment, isActive: true },
	],
	[pageRoutes.admin.edit_appointment]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Appointment", path: pageRoutes.admin.appointment, isActive: false },
		{ name: "Edit Appointment", path: pageRoutes.admin.edit_appointment, isActive: true },
	],
	edit_timeschedule: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "branches", path: pageRoutes.admin.branches, isActive: false },
		{ name: "time schedule", path: pageRoutes.admin.time_schedule, isActive: true },
	],
	[pageRoutes.admin.reference]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Reference", path: pageRoutes.admin.reference, isActive: true },
	],
	[pageRoutes.admin.symptom]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Symptom", path: pageRoutes.admin.symptom, isActive: true },
	],
	[pageRoutes.admin.profile]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Profile", path: pageRoutes.admin.profile, isActive: true },
	],
	[pageRoutes.admin.intern_doctor]: [
		{ name: "Doctor Profile", path: pageRoutes.admin.profile, isActive: false },
		{ name: "Intern Doctor", path: pageRoutes.admin.intern_doctor, isActive: true },
	],
	[pageRoutes.admin.letter_format_content]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Letter Format", path: pageRoutes.admin.letter_format_content, isActive: true },
	],

	[pageRoutes.admin.letter_generate]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Letter Generate", path: pageRoutes.admin.letter_generate, isActive: true },
	],
	[pageRoutes.vendor.user]: [{ name: "User", path: pageRoutes.vendor.user, isActive: true }],
	[pageRoutes.vendor.user_permission]: [
		{ name: "User", path: pageRoutes.vendor.user, isActive: true },
		{ name: "User Permission", path: pageRoutes.vendor.user_permission, isActive: true },
	],
	[pageRoutes.patient.patient_files]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Prescription-file", path: pageRoutes.patient.patient_files, isActive: true },
	],
	[pageRoutes.patient.appointments]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Appointment", path: pageRoutes.patient.appointments, isActive: true },
	],
	[pageRoutes.patient.patient_profile]: [
		{ name: "Dashboard", path: pageRoutes.admin.home, isActive: false },
		{ name: "Profile", path: pageRoutes.patient.patient_profile, isActive: true },
	],
};

export const validationMessages = {
	accepted: ":attribute must be accepted.",
	after: ":attribute must be after :after.",
	after_or_equal: ":attribute must be equal or after :after_or_equal.",
	alpha: ":attribute must contain only alphabetic characters.",
	alpha_dash: ":attribute may only contain alpha-numeric characters, as well as dashes and underscores.",
	alpha_num: ":attribute must be alphanumeric.",
	before: ":attribute must be before :before.",
	before_or_equal: ":attribute must be equal or before :before_or_equal.",
	between: {
		numeric: ":attribute must be between :min and :max.",
		string: ":attribute must be between :min and :max characters.",
	},
	confirmed: ":attribute confirmation does not match.",
	email: ":attribute format is invalid.",
	date: ":attribute is not a valid date format.",
	def: ":attribute attribute has errors.",
	digits: ":attribute must be :digits digits.",
	digits_between: ":attribute must be between :min and :max digits.",
	different: ":attribute and :different must be different.",
	in: "selected :attribute is invalid.",
	integer: ":attribute must be an integer.",
	hex: ":attribute should have hexadecimal format",
	min: {
		numeric: ":attribute must be at least :min.",
		string: ":attribute must be at least :min characters.",
	},
	max: {
		numeric: ":attribute may not be greater than :max.",
		string: ":attribute may not be greater than :max characters.",
	},
	not_in: "selected :attribute is invalid.",
	numeric: ":attribute must be a number.",
	present: ":attribute must be present (but can be empty).",
	required: ":attribute is required.",
	required_if: ":attribute is required when :other is :value.",
	required_unless: ":attribute is required when :other is not :value.",
	required_with: ":attribute is required when :field is not empty.",
	required_with_all: ":attribute is required when :fields are not empty.",
	required_without: ":attribute is required when :field is empty.",
	required_without_all: ":attribute is required when :fields are empty.",
	same: ":attribute and :same fields must match.",
	size: {
		numeric: ":attribute must be :size.",
		string: ":attribute must be :size characters.",
	},
	string: ":attribute must be a string.",
	url: ":attribute format is invalid.",
	regex: ":attribute format is invalid.",
	attributes: {},
};

export const admin_role = "admin";
export const doctor_role = "doctor";
export const staff_role = "staff";
export const patient_role = "patient";
export const prescription_role = "prescription";
export const super_admin = "super_admin";

export const roles = {
	admin_role: admin_role,
	doctor_role: doctor_role,
	staff_role: staff_role,
	patient_role: patient_role,
	prescription: prescription_role,
	super_admin: prescription_role,
};

export const defaultRedirectionByRoles = {
	[admin_role]: pageRoutes.admin.home,
	[doctor_role]: pageRoutes.admin.dashboard_doctor,
	[staff_role]: pageRoutes.admin.appointment,
	[patient_role]: pageRoutes.patient.patient_dashboard,
	[prescription_role]: pageRoutes.prescription.appointment,
	[super_admin]: pageRoutes.admin.home,
};

export const guestRedirectionLink = () => {
	return pageRoutes.admin.login;
};

export const defaultRedirectionLink = (role = null) => {
	const user = useSelector((state) => state.auth?.user);
	let userRole = role == null ? user?.role_name : role;
	return userRole ? defaultRedirectionByRoles[userRole] : guestRedirectionLink();
};

export const combinationColors = [
	{
		backgroundColor: "#17CB83",
		color: "#000000",
	},
	{
		backgroundColor: "#6ED04F",
		color: "#000000",
	},
	{
		backgroundColor: "#6BE6D3",
		color: "#000000",
	},
	{
		backgroundColor: "#9ADFE2",
		color: "#000000",
	},
	{
		backgroundColor: "#3C7A12",
		color: "#ffffff",
	},
	{
		backgroundColor: "#938FB3",
		color: "#000000",
	},
	{
		backgroundColor: "#A94927",
		color: "#ffffff",
	},
	{
		backgroundColor: "#854028",
		color: "#ffffff",
	},
	{
		backgroundColor: "#569C66",
		color: "#000000",
	},
	{
		backgroundColor: "#4E92CA",
		color: "#000000",
	},
	{
		backgroundColor: "#3AE6BD",
		color: "#000000",
	},
	{
		backgroundColor: "#67BAAB",
		color: "#000000",
	},
	{
		backgroundColor: "#9CB8AA",
		color: "#000000",
	},
	{
		backgroundColor: "#91B7B9",
		color: "#000000",
	},
	{
		backgroundColor: "#F2D1FE",
		color: "#000000",
	},
	{
		backgroundColor: "#DDF01F",
		color: "#000000",
	},
	{
		backgroundColor: "#1BAF17",
		color: "#ffffff",
	},
	{
		backgroundColor: "#4ACF4C",
		color: "#000000",
	},
	{
		backgroundColor: "#41F5F5",
		color: "#000000",
	},
	{
		backgroundColor: "#ECEB22",
		color: "#000000",
	},
	{
		backgroundColor: "#F2456B",
		color: "#ffffff",
	},
	{
		backgroundColor: "#35C631",
		color: "#000000",
	},
	{
		backgroundColor: "#8EEA49",
		color: "#000000",
	},
	{
		backgroundColor: "#689A73",
		color: "#000000",
	},
	{
		backgroundColor: "#53F521",
		color: "#000000",
	},
	{
		backgroundColor: "#EFC68A",
		color: "#000000",
	},
	{
		backgroundColor: "#825CAC",
		color: "#ffffff",
	},
	{
		backgroundColor: "#1C37CC",
		color: "#ffffff",
	},
	{
		backgroundColor: "#34582A",
		color: "#ffffff",
	},
	{
		backgroundColor: "#9B853B",
		color: "#000000",
	},
	{
		backgroundColor: "#024110",
		color: "#ffffff",
	},
	{
		backgroundColor: "#C16A94",
		color: "#000000",
	},
	{
		backgroundColor: "#144987",
		color: "#ffffff",
	},
	{
		backgroundColor: "#9B5C25",
		color: "#ffffff",
	},
	{
		backgroundColor: "#3C7D41",
		color: "#ffffff",
	},
	{
		backgroundColor: "#16A52C",
		color: "#ffffff",
	},
];
