import React, { useEffect, useState } from "react";
import { api, helper } from "../services";

export default function useFetchData({ endpoints, callback, defaultData, sideEffectCall = "sideEffectCall" }) {
	const [state, setState] = useState(defaultData);

	const callApi = () => {
		if (false == Array.isArray(endpoints)) {
			api
				.get(endpoints)
				.then(callback)
				.then(setState)
				.catch(({ errors }) => {
                    if(errors){
                        helper.toaster.error(errors.message);
                    }
                    
				})
				.then(setState(defaultData));
		} else {
			let promisses = [];
			endpoints.forEach((endpoint) => {
				promisses.push(api.get(endpoint));
			});

			Promise.all(promisses)
				.then(callback)
				.then(setState)
				.catch(({ errors }) => {
                    if(errors){
                        helper.toaster.error(errors.message);
                    }
				});
		}
	};

	useEffect(() => {
		if (sideEffectCall != "sideEffectCall" && sideEffectCall) {
			callApi();
		} else if (sideEffectCall == "sideEffectCall") {
			callApi();
		}
	}, [sideEffectCall]);

	return [state, callApi];
}
