import React, { useEffect, useState } from "react";
import themeConfig from "@/configs/themeConfig";
import ReactButton from "@/components/admin/ui/reactButton";
import useMenuData from "@/hooks/useMenuData";
import { Button, Container, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { store } from "@/store";
import { handleLogout } from "@/store/toolkit/auth";
import { useSelector } from "react-redux";
import { handleRedirect, isEmpty } from "@/services/helper";
import ReactImage from "@/components/admin/ui/ReactImage";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "@/configs";
import apiConfigNew from "@/configs/apiConfigNew";
import useFetchData from "@/hooks/useFetchData";
import ResetPasswordModal from "../login/partial/ResetPasswordModal";
import useWidth from "@/hooks/useWidth";
import DoctorModal from "../login/partial/DoctorModal";
import DoctorBranchModal from "../login/partial/DoctorBranchModal";
import { checkUserPermission } from "@/constant/permissions";

export default function Header() {
	const { width } = useWidth();

	const [show, setShow] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [doctor, setDoctor] = useState(false);
	const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);
	const [vendorName, setVendorName] = useState();

	const navigate = useNavigate();
	const menus = useMenuData();
	const user = useSelector((state) => state.auth?.user);
	const previous_state = useSelector((state) => state.auth?.previousAuth);

	const handleLogouts = () => {
		if (previous_state.token == null) {
			store.dispatch(handleLogout());
			window.location.href = user?.role_name == "patient" ? pageRoutes.patient.patient_login : pageRoutes.admin.login;
		} else {
			store.dispatch(handleLogout());
			setTimeout(() => {
				window.location.href = pageRoutes.vendor.vendors;
			}, 200);
		}
	};

	const [settingData] = useFetchData({
		defaultData: {},
		endpoints: apiConfigNew.admin.setting,
		callback: (data) => {
			return data;
		},
	});

	// useEffect(() => {
	// 	if (user?.branch_id == null) {
	// 		setModalShow(true);
	// 	}
	// }, [user?.branch_id]);

	const [vendor, callApi] = useFetchData({
		sideEffectCall: ["patient", "doctor"]?.includes(user?.role_name) && user?.branch_name,
		defaultData: [],
		endpoints: `${apiConfigNew.admin.get_vendor}`,
		callback: (data) => {
			return data;
		},
	});

	useEffect(() => {
		if (["patient", "doctor"]?.includes(user?.role_name) && user?.branch_name) {
			if (vendor) {
				const id = vendor.filter((item) => item.is_default_vendor == true);
				setVendorName(id[0]?.label);
			}
		}
	}, [vendor, user]);

	return (
		<>
			<header className="header--wrapper">
				<Navbar>
					<Container fluid>
						<Navbar.Brand href="#!" className="d-flex align-items-center justify-content-between column-gap-3">
							<ReactImage image={themeConfig.defaultIamge} src={settingData?.logo} alt="logo" className="logo-img w-100 object-cover" />
						</Navbar.Brand>
						<span className="sidebar--title">{settingData?.sidebar_name}</span>
						{user?.role_name !== "prescription" && (
							<ReactButton
								size="sm"
								className="d-lg-none d-md-block d-sm-none d-xs-none mobile--menu-bth"
								onClick={() => {
									setShow(true);
								}}
							>
								<Icon icon="gg:menu-right" className="d-block" />
							</ReactButton>
						)}
						{width >= 575 && (
							<Navbar.Collapse className={`${user?.role_name == "prescription" ? "headers--link" : "header--link"}`}>
								{["patient", "doctor"]?.includes(user?.role_name) && user?.branch_name && (
									<ReactButton className="mx-3 role--changes-vendor--btn radiusS font14 btn btn-primary" onClick={() => setDoctor(true)}>
										{vendorName}
									</ReactButton>
								)}
								{["admin", "doctor"]?.includes(user?.role_name) && user?.branch_name && (
									<ReactButton className="mx-3 admin--add-details--btn text-white radiusS font14  btn btn-primary" onClick={() => setModalShow(true)}>
										{user?.branch_name}
									</ReactButton>
								)}

								<NavDropdown
									title={
										<div className="d-flex align-items-center">
											<div className="ms-3">
												<h4>
													{isEmpty(user?.firstname) ? "" : user?.firstname} &nbsp;
													{isEmpty(user?.lastname) ? "" : user?.lastname}
												</h4>
												<span>{user?.role_name}</span>
											</div>
											<ReactImage src={user?.image !== null ? user?.image : ""} image={themeConfig.avtarImage} className="user--img  ms-2" />
										</div>
									}
									className="header--dropdown"
								>
									{user?.role_name === "prescription" && (
										<NavDropdown.Item
											onClick={() => navigate(pageRoutes.prescription.appointment)}
											className="d-flex align-items-center text-capitalize"
										>
											<Icon icon="icon-park-outline:appointment" className="d-block" />
											Appointments
										</NavDropdown.Item>
									)}
									{user?.role_name === "prescription" && (
										<NavDropdown.Item onClick={() => navigate(pageRoutes.prescription.patient)} className="d-flex align-items-center text-capitalize">
											<Icon icon="guidance:in-patient" className="d-block" />
											Patient
										</NavDropdown.Item>
									)}

									{["doctor", "staff", "prescription"].includes(user?.role_name) && (
										<NavDropdown.Item
											onClick={() => navigate(user?.role_name == "prescription" ? pageRoutes.prescription.profile : pageRoutes.admin.profile)}
											className="d-flex align-items-center text-capitalize"
										>
											<Icon icon="healthicons:ui-user-profile" className="d-block" />
											My profile
										</NavDropdown.Item>
									)}

									{user?.role_name === "patient" && (
										<NavDropdown.Item
											onClick={() => navigate(pageRoutes.patient.patient_profile)}
											className="d-flex align-items-center text-capitalize"
										>
											<Icon icon="material-symbols:home" className="d-block" />
											My Profile
										</NavDropdown.Item>
									)}
									{user?.role_name !== "patient" && (
										<NavDropdown.Item className="d-flex align-items-center text-capitalize" onClick={() => setResetPasswordModalShow(true)}>
											<Icon icon="mdi:lock-reset" className="d-block" />
											Change Password
										</NavDropdown.Item>
									)}
									<Button onClick={handleLogouts} className="d-flex align-items-center dropdown-item">
										<Icon icon="ic:round-logout" className="d-block" />
										Logout
									</Button>
								</NavDropdown>
							</Navbar.Collapse>
						)}
					</Container>
				</Navbar>
			</header>

			{user?.role_name !== "prescription" && (
				<header className="mobile-header--wrapper d-lg-none d-md-block d-xs-none">
					<Offcanvas show={show} scroll className="mobile--offcanvas border-0">
						<Offcanvas.Header>
							<div className={`d-flex flex-column w-100 row-gap-2  ${user?.role_name == "super_admin" ? "mt-0" : "mt-3"}`}>
								{["patient", "doctor"]?.includes(user?.role_name) && user?.branch_name && (
									<ReactButton className=" role--changes-vendor--btn radiusS font14 btn btn-primary" onClick={() => setDoctor(true)}>
										{vendorName}
									</ReactButton>
								)}
								{["admin", "doctor"]?.includes(user?.role_name) && user?.branch_name && (
									<ReactButton className=" admin--add-details--btn text-white radiusS font14  btn btn-primary" onClick={() => setModalShow(true)}>
										{user?.branch_name}
									</ReactButton>
								)}
							</div>
							<NavDropdown
								title={
									<div className="d-flex align-items-center mobile---header">
										<ReactImage src={user?.image !== null ? user?.image : ""} image={themeConfig.avtarImage} className="user--img ms-2" />
										<div className="ms-3">
											<h4>
												{isEmpty(user?.firstname) ? "" : user?.firstname}&nbsp;
												{isEmpty(user?.lastname) ? "" : user?.lastname}
											</h4>
											<span>{user?.role_name}</span>
										</div>
									</div>
								}
								className="header--dropdown"
							>
								{user?.role_name === "prescription" && (
									<NavDropdown.Item
										onClick={() => navigate(pageRoutes.prescription.appointment)}
										className="d-flex align-items-center text-capitalize"
									>
										<Icon icon="icon-park-outline:appointment" className="d-block" />
										Appointments
									</NavDropdown.Item>
								)}
								{user?.role_name === "prescription" && (
									<NavDropdown.Item onClick={() => navigate(pageRoutes.prescription.patient)} className="d-flex align-items-center text-capitalize">
										<Icon icon="guidance:in-patient" className="d-block" />
										Patient
									</NavDropdown.Item>
								)}

								{["doctor", "staff", "prescription"].includes(user?.role_name) && (
									<NavDropdown.Item
										onClick={() => navigate(user?.role_name == "prescription" ? pageRoutes.prescription.profile : pageRoutes.admin.profile)}
										className="d-flex align-items-center text-capitalize"
									>
										<Icon icon="healthicons:ui-user-profile" className="d-block" />
										My profile
									</NavDropdown.Item>
								)}

								{user?.role_name === "patient" && (
									<NavDropdown.Item
										onClick={() => navigate(pageRoutes.patient.patient_profile)}
										className="d-flex align-items-center text-capitalize"
									>
										<Icon icon="material-symbols:home" className="d-block" />
										My Profile
									</NavDropdown.Item>
								)}
								{user?.role_name !== "patient" && (
									<NavDropdown.Item className="d-flex align-items-center text-capitalize" onClick={() => setResetPasswordModalShow(true)}>
										<Icon icon="mdi:lock-reset" className="d-block" />
										Change Password
									</NavDropdown.Item>
								)}
							</NavDropdown>

							<ReactButton
								size="sm"
								className="close--btn"
								onClick={() => {
									setShow(false);
								}}
							>
								<Icon icon="mingcute:close-fill" className="d-block" />
							</ReactButton>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<div className="sidebar--menu">
								<ul className="p-0 m-0">
									{menus.map((item, index) => {
										return (
											<li key={index} className={`sub--menu ${item.isActive ? "active" : "disable"} ${item.isActiveColor ? "active-color" : ""}`}>
												{item.hasOwnProperty("subItems") ? (
													<ReactButton variant="transparent" className="d-flex align-items-center submenu--link" onClick={item.click}>
														<span className="menu--side">
															<Icon icon={item.icon} />
														</span>
														<span className="d-block text-capitalize menu--title">{item.label}</span>
														<span className="menu--arrow d-block">
															<Icon icon={item.isActive ? "iconamoon:arrow-down-2" : "iconamoon:arrow-right-2-duotone"} />
														</span>
													</ReactButton>
												) : (
													<a href={item.link} className="d-flex align-items-center submenu--link">
														<span className="menu--side">
															<Icon icon={item.icon} />
														</span>
														<span className="d-block text-capitalize menu--title">{item.label}</span>
													</a>
												)}
												{item.hasOwnProperty("subItems") && (
													<ul className={`d-block sub-menu--list ${item.isActive ? "d-block" : "d-none"}`}>
														{item.subItems.map((subItem, subIndex) => {
															return (
																<li key={subIndex}>
																	<a
																		href={subItem.link}
																		className={`d-flex align-items-center text-capitalize ${subItem.isActive ? "active" : "disable"}`}
																	>
																		{subItem.icon && <Icon icon={subItem.icon} className="d-block" />}
																		<>{subItem.label}</>
																	</a>
																</li>
															);
														})}
													</ul>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						</Offcanvas.Body>
						<div className="mobile--footer-box">
							<ReactButton className="mobile--logout-btn text-capitalize" onClick={handleLogouts}>
								<Icon icon="uiw:logout" className="d-block me-2" />
								logout
							</ReactButton>
						</div>
					</Offcanvas>
				</header>
			)}

			{/* SELECT--DOCTOR--BRANCH--MODAL */}
			{modalShow && <DoctorBranchModal setModalShow={setModalShow} modalShow={modalShow} />}
			{/* END--DOCTOR--BRANCH--MODAL */}

			{/* SELECT--DOCTOR--BRANCH--MODAL */}
			{doctor && <DoctorModal setModalShow={setDoctor} modalShow={doctor} branch={vendor} callApi={callApi} />}
			{/* END--DOCTOR--BRANCH--MODAL */}

			{/* RESET--PASSWORD--MODAL--START */}
			{resetPasswordModalShow && <ResetPasswordModal setModalShow={setResetPasswordModalShow} modalShow={resetPasswordModalShow} />}
			{/* RESET--PASSWORD--MODAL--END */}
		</>
	);
}
