import React from "react";
import { Modal } from "react-bootstrap";
import ReactButton from "./reactButton";
import { Icon } from "@iconify/react";

const ReactModal = ({
	size,
	isLoader,
	title,
	isHeader = true,
	show,
	onHide,
	hideCancleIcon = true,
	saveButtonShowOrNot = true,
	children,
	btnTitle = "Save",
	onSave,
	disabled,
	scrollable,
	...rest
}) => {
	return (
		<Modal {...rest} size={size} onHide={onHide} centered show={show} className="dynamic--modal" scrollable={scrollable}>
			{isHeader && <Modal.Header className="justify-content-between">
				<Modal.Title id="contained-modal-title-vcenter text-capitalize" as="h4">
					{title}
				</Modal.Title>
				{hideCancleIcon && (
					<ReactButton className="btn-close" size="sm" onClick={onHide}>
						<Icon icon="iconamoon:close-bold" />
					</ReactButton>
				)}
			</Modal.Header>}
			<Modal.Body>{children}</Modal.Body>
			{saveButtonShowOrNot && (
				<Modal.Footer>
					<ReactButton disabled={disabled} loader={isLoader} className="mx-3 save--button " onClick={onSave}>
						{btnTitle}
					</ReactButton>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default ReactModal;
