import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import BreadcrumbLayout from "@/components/admin/ui/BreadcrumbLayout";
import { pageRoutes } from "@/configs";
import { getAuthState } from "@/store/selector";

const withoutLayoutRoutes = [pageRoutes.admin.appointmentMonitor];

export default function AdminLayout() {
	let path = window.location.pathname;
	const _data = getAuthState();

	return (
		<div className="admin-panel">
			{withoutLayoutRoutes.includes(path) ? (
				<Suspense fallback={"Loading..."}>
					<Outlet />
				</Suspense>
			) : (
				<>
					<Header />
					<div className="admin--pages-main--wrapper">
						<Sidebar />
						<div className="admin--pages-wrapper">
							{["admin", "patient"].includes(_data?.user?.role_name) && <BreadcrumbLayout />}
							<Suspense fallback={"Loading..."}>
								<Outlet />
							</Suspense>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
