import { helper } from "@/services";
import axios from "@/services/axios";
import { isEmpty } from "@/services/helper";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";

const isImageUpload = (data) => {
	const image_form_data = ["image", "prescription_image", "icon", "thumbnail", "logo", "footer_logo", "video", "counter_image"];
	const _image = Object.keys(data).some((item) => image_form_data.includes(item));
	return _image;
};

export default function useHandlePostAPI({
	defaultFormState = {},
	method = "post",
	preparePayload = () => {
		return {};
	},
	endpoints,
	callback,
	populateFormData = null,
	fetchDefaultStateData = {
		endpoint: null,
		fetchCallback: () => {
			return {};
		},
	},
}) {
	const [formState, setFormState] = useState(defaultFormState);
	const [state, setState] = useState({
		isLoader: false,
	});

	const changeState = (obj) => {
		setState((prev) => ({
			...prev,
			...obj,
		}));
	};

	const changeFormState = useCallback(
		(obj) => {
			setFormState((prev) => ({
				...prev,
				...obj,
			}));
		},
		[formState]
	);

	const callApiDefaultData = (data) => {
		if (helper.isDefined(data?.endpoint)) {
			axios
				.get(data?.endpoint)
				.then(fetchDefaultStateData?.fetchCallback)
				.then((data) => {
					if (false == data?.isAlreadyConverted) {
						return defineDataWithStates(data);
					}

					return data;
				})
				.then(changeFormState)
				.catch(console.error);
		}
	};

	const defineDataWithStates = (myFormData) => {
		let _formState = { ...formState };
		if (helper.isDefined(myFormData)) {
			for (const key in myFormData) {
				if (Object.hasOwnProperty.call(formState, key)) {
					const element = myFormData[key];
					_formState[key] = element;
				}
			}
			changeFormState(_formState);
		}
	};

	useEffect(() => {
		defineDataWithStates(populateFormData);
	}, [populateFormData]);

	useEffect(() => {
		callApiDefaultData(fetchDefaultStateData);
	}, [fetchDefaultStateData?.endpoint]);

	const onChange = ({ target: { name, value } }) => {
		setFormState((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const clearFormState = () => {
		setFormState({ ...defaultFormState });
	};

	const handlePostApi = (payload) => {
		changeState({
			isLoader: true,
		});

		let formPayload = { ...payload };
		let additionalSetPayload = preparePayload(formPayload);

		if (helper.isDefined(additionalSetPayload)) {
			formPayload = {
				...additionalSetPayload,
			};
		}

		if (isImageUpload(formPayload)) {
			let _formPayload = new FormData();

			for (const key in formPayload) {
				if (Object.hasOwnProperty.call(formPayload, key)) {
					const element = formPayload[key];
					if (Array.isArray(element)) {
						element.forEach((item) => {
							_formPayload.append(`${key}`, item);
						});
					} else {
						_formPayload.append(key, element);
					}
				}
			}
			formPayload = _formPayload;
		}

		axios[method](endpoints, formPayload)
			.then((res) => {
				setFormState(defaultFormState);

				// if (helper.isDefined(res?.message)) {
				// 	helper.toaster.success(res?.message);
				// }

				return res;
			})
			.then(callback)
			.catch((error) => {
				console.error(error?.errors);
				switch (error.status) {
					case 422:
						// if (error.errors) {
						changeState({
							errors: error.errors,
						});
						// } else {
						Object.values(error.errors).map((error) => {
							helper.toaster.error(error);
						});
						// }

						break;

					default:
						if (false == helper.isEmpty(error.errors?.message)) {
							helper.toaster.error(error.errors?.message);
						} else {
							Object.values(error.errors).map((error) => {
								helper.toaster.error(error);
							});
						}
						break;
				}
			})
			.finally(() => {
				changeState({
					isLoader: false,
				});
			});
	};

	const _formElementEvents = (name, errors = {}) => {
		return {
			name: name,
			error: helper.isDefined(errors) ? errors[name] : {},
			value: formState[name],
			onChange: onChange,
		};
	};

	return {
		formState,
		isLoader: state.isLoader,
		callAPI: handlePostApi,
		onChange,
		clearFormState,
		register: _formElementEvents,
		validationErrors: state.errors,
		changeFormState,
		callApiDefaultData,
	};
}
