import ReactModal from "@/components/admin/ui/ReactModal";
import ReactSelect from "@/components/admin/forms/ReactSelect";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { isEmpty } from "@/services/helper";
import { helper } from "@/services";
import apiConfigNew from "@/configs/apiConfigNew";
import { store } from "@/store";
import { handleLogin } from "@/store/toolkit/auth";
import { useSelector } from "react-redux";
import useFetchData from "@/hooks/useFetchData";
import { pageRoutes } from "@/configs";
import { useNavigate } from "react-router-dom";

const DoctorBranchModal = ({ modalShow, setModalShow }) => {
	const user = useSelector((state) => state.auth?.user);
	const data = useSelector((state) => state.auth);
	const [branchId, setBranchId] = useState(user?.branch_id);
	const navigate = useNavigate();

	const [branch] = useFetchData({
		defaultData: [],
		endpoints: `${apiConfigNew.admin.list_branch}?branch_type=${true}`,
		callback: (data) => {
			return data;
		},
	});

	// useEffect(() => {
	// 	if (branch) {
	// 		const id = branch.filter((item) => item.is_default_branch == true)
	// 		setBranchId(id[0]?.label)
	// 	}
	// }, [branch, user])

	const handleRedirect = (roleName) => {
		const redirectObj = {
			admin: pageRoutes.admin.home,
			super_admin: pageRoutes.vendor.vendors,
			doctor: pageRoutes.admin.dashboard_doctor,
			patient: pageRoutes.patient.patient_dashboard,
			staff: pageRoutes.admin.appointment,
			prescription: pageRoutes.prescription.appointment,
		};
		return redirectObj[roleName];
	};

	const handleBranchChange = () => {
		store.dispatch(
			handleLogin({
				...data,
				user: {
					...data.user,
					branch_id: branchId,
					branch_name: branch?.find((item) => item?.value == branchId)?.label,
				},
			})
		);

		navigate(handleRedirect(user?.role_name));
	};

	return (
		<div>
			<ReactModal
				size="md"
				title="select Branch"
				show={modalShow}
				btnTitle={"Save"}
				onSave={() => {
					if (!isEmpty(branchId)) {
						handleBranchChange();
						setModalShow(false);
					} else {
						helper.toaster.error("Please Select Branch");
					}
					// localStorage.removeItem("reloadWindow");
				}}
				onHide={() => {
					if (user?.branch_id) {
						setModalShow(false);
					} else {
						setModalShow(true);
					}
				}}
				hideCancleIcon={user?.branch_id ? true : false}
			>
				<Col xl={12}>
					<ReactSelect
						placeholder="Select Branch"
						label="Branch"
						onChange={(e) => {
							setBranchId(e?.value);
							// localStorage.removeItem("reloadWindow");
						}}
						disabled={user?.role_name == "staff"}
						value={branchId}
						notShowSelectText={true}
						options={branch || []}
						className="mx-3"
					/>
				</Col>
			</ReactModal>
		</div>
	);
};

export default DoctorBranchModal;
