import _, { isArray } from "lodash";
import { toast } from "react-hot-toast";
import { appConfig, pageRoutes, themeConfig } from "../configs";
import { breadCrumbs, combinationColors } from "../constant/data";
import { store } from "../store";
import Swal from "sweetalert2";
import { getAuthState } from "@/store/selector";
import { helper } from ".";

export const isEmpty = (value) => {
	if (value == null || value === "null") {
		return true;
	}
	if (typeof value == "object") {
		return Object.keys(value).length === 0;
	}
	return (isArray(value) && value.length === 0) || value === undefined || value === "undefined" || value == null || value === "";
};

export const prepateSelectDropdown = (array, field1 = null, field2) => {
	if (isEmpty(array)) {
		return [];
	}
	return array
		.map((item) => {
			return {
				label: isEmpty(field1) ? item : item[field1],
				value: isEmpty(field2) ? item : item[field2],
			};
		})
		.filter((e) => !isEmpty(e.label));
};

export const isDefined = (value) => {
	return !isEmpty(value);
};

export const toaster = {
	error: (message, config = {}) => {
		toast.error(message);
	},
	success: (message, config = {}) => {
		toast.success(message);
	},
};

export const userTypeByAccess = () => {
	return window.location.href.includes("patient") ? "patient" : "admin";
};

export const isEmptyObject = (value) => {
	return value?.every((item) => isEmpty(item));
};

export const checkUserHasPermissionOrNot = (permissionsName) => {
	const allPermissions = getAuthState();
	const _permission = allPermissions?.permissions?.some((item) => item.permission_name == permissionsName);
	return _permission;
};

export const userPermissions = () => {
	let { permissions = [] } = getAuthState();
	const _permissionObject = {};
	permissions?.map((item) => {
		_permissionObject[item?.permission_name?.replace(" ", "_")] = item.permission_name;
	});
	return _permissionObject;
};

export const getUserRole = () => {
	let user = getAuthState();
	const redirectObj = {
		admin: pageRoutes.admin.home,
		doctor: pageRoutes.admin.dashboard_doctor,
		patient: pageRoutes.patient.patient_dashboard,
		staff: pageRoutes.admin.appointment,
		super_admin: pageRoutes.vendor.vendors,
		prescription: pageRoutes.prescription.appointment,
	};

	return redirectObj[user?.user?.role_name];
};

export const getbreadCrumbs = (pageType = null) => {
	let routeName = window.location.pathname;
	let _breadCrumbs = breadCrumbs[routeName] || [];

	if (isEmpty(_breadCrumbs)) {
		_breadCrumbs = breadCrumbs[pageType];
	}

	const __breadCrumbs = _breadCrumbs?.map((item) => {
		if (item.name == "Dashboard") {
			return { ...item, path: getUserRole() };
		}
		return item;
	});

	return __breadCrumbs;
};

export const sendHeader = (access_token) => {
	return {
		headers: {
			"Content-Type": "application/json",
			Authorization: access_token,
		},
	};
};

export const getStoreState = (key) => {
	let _store = store.getState();
	return isDefined(_store[key]) ? _store[key] : {};
};

export const base64ToBlob = (base64) => {
	var base64ImageWithoutPrefix = base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
	let byteCharacters = atob(base64ImageWithoutPrefix);
	var byteNumbers = new Array(byteCharacters.length);
	for (var i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	var byteArray = new Uint8Array(byteNumbers);

	var myBlob = new Blob([byteArray], { type: "image/png" });

	return myBlob;
};

export const isAuth = () => {
	return false == isEmpty(window.localStorage.getItem(appConfig.localStorage.token));
};

export const RoleName = () => {
	const redux_data = getAuthState();
	const get_role = {
		admin: "admin",
		doctor: "admin",
		staff: "admin",
		prescription: "prescription",
		patient: "patient",
		super_admin: "vender",
	};

	return get_role[redux_data?.user?.role_name];
	// return redux_data?.user?.role_name == "doctor" ? "admin" : redux_data?.user?.role_name;
};

export const userRole = () => {
	return localStorage.getItem(appConfig.localStorage.role);
};

export const userRoles = () => {
	const cookieData = Cookies.get("X18ZM4ZIMV");
	let userData = {};
	if (cookieData != undefined) {
		userData = JSON.parse(cookieData);
	}

	return userData;
};

export const showErrorToaster = (err) => {
	if (err.status == 400 || err.status == 401 || err.status == 422 || err.status == 404) {
		toaster.error(err.errors.message);
	} else {
		toaster.error(err);
	}
};

export const getImageUrl = (url) => {
	if (isEmpty(url)) {
		return themeConfig.defaultIamge;
	}
	const isAbsoluteUrl = /^(?:\w+:)\/\//.test(url);
	if (isAbsoluteUrl) {
		return url;
	} else {
		return `${appConfig.host}${url}`.replace(/([^:]\/)\/+/g, "$1");
	}
};

export const imgObjToBase64 = (imgObj) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.onerror = (error) => {
			reject(error);
		};
		reader.readAsDataURL(imgObj);
	});
};

export const checkUrlType = (url) => {
	// Regular expressions to match common video and image file extensions
	const videoExtensions = /\.(mp4|mov|avi|wmv|flv|mkv)$/i;
	const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i;

	// Test if the URL matches the video or image extensions
	if (url.match(videoExtensions)) {
		return "video";
	} else if (url.match(imageExtensions)) {
		return "image";
	} else {
		return null;
	}
};

export const getRandomColor = () => {
	return combinationColors[Math.round(Math.random() * combinationColors.length)];
};

export const avtarDefaultNameAndColor = (name) => {
	const parts = name.split(" ");
	let code = "";

	if (Array.isArray(parts) && parts.length > 1) {
		code += parts[0].charAt(0).toUpperCase();
		code += parts[parts.length - 1].charAt(0).toUpperCase();
	} else {
		code += name.charAt(0).toUpperCase();
		code += name.charAt(1).toUpperCase();
	}

	return code;
};

export const sweetAlert = {
	delete: (message = "") => {
		return new Promise((resolve) => {
			Swal.fire({
				title: "Are you sure?",
				text: message ? `Are you sure you want to Re-transfer this appointment` : `Are you sure you want to delete this record`,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: message ? `Save` : "Delete !",
				customClass: {
					container: "custom-container",
					popup: "custom-popup",
					title: "custom-title",
					htmlContainer: "custom-html",
					confirmButton: "custom-confirm",
					cancelButton: "custom-cancel",
				},
			}).then((result) => {
				if (result.isConfirmed) {
					resolve(result);
				}
			});
		});
	},
	cancel: (message = "") => {
		return new Promise((resolve) => {
			Swal.fire({
				title: "Are you sure?",
				text: `Are you sure you want to cancel this appointment`,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: `Save`,
				customClass: {
					container: "custom-container",
					popup: "custom-popup",
					title: "custom-title",
					htmlContainer: "custom-html",
					confirmButton: "custom-confirm",
					cancelButton: "custom-cancel",
				},
			}).then((result) => {
				if (result.isConfirmed) {
					resolve(result);
				}
			});
		});
	},

	staff: (message = "") => {
		return new Promise((resolve) => {
			Swal.fire({
				title: "Are you sure?",
				text: `You want to reset password?`,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: `Yes`,
				customClass: {
					container: "custom-container",
					popup: "custom-popup",
					title: "custom-title",
					htmlContainer: "custom-html",
					confirmButton: "custom-confirm",
					cancelButton: "custom-cancel",
				},
			}).then((result) => {
				if (result.isConfirmed) {
					resolve(result);
				}
			});
		});
	},

	restore: (message = "") => {
		return new Promise((resolve) => {
			Swal.fire({
				title: "Are you sure?",
				text: `Are you sure you want to restore?`,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: `Restore`,
				customClass: {
					container: "custom-container",
					popup: "custom-popup",
					title: "custom-title",
					htmlContainer: "custom-html",
					confirmButton: "custom-confirm",
					cancelButton: "custom-cancel",
				},
			}).then((result) => {
				if (result.isConfirmed) {
					resolve(result);
				}
			});
		});
	},

	seed_vendor: (message = "") => {
		return new Promise((resolve) => {
			Swal.fire({
				title: "Seed default data?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: `Save`,
				customClass: {
					container: "custom-container",
					popup: "custom-popup",
					title: "custom-title",
					htmlContainer: "custom-html",
					confirmButton: "custom-confirm",
					cancelButton: "custom-cancel",
				},
			}).then((result) => {
				if (result.isConfirmed) {
					resolve(result);
				}
			});
		});
	},
	success: (message = "Your work has been saved") => {
		Swal.fire({
			position: "top-end",
			text: message,
			showConfirmButton: false,
			width: "300px",
			timer: 1500,
		});
	},

	deleted: (message = "Record deleted") => {
		Swal.fire("Deleted!", message, "success");
	},
};

export const reArrangeArrayWithCurrentElement = (currentIndex, array) => {
	if (isEmpty(array)) {
		return [];
	}
	return [...array.slice(currentIndex, array.length), ...array.slice(0, currentIndex)];
};

export const base64ToBinary = (base64) => {
	// Remove the data URL prefix if present
	const base64Data = base64.split(",")[1] || base64;

	// Decode base64 to binary string
	const binaryString = atob(base64Data);

	// Create a byte array from the binary string
	const len = binaryString.length;
	const bytes = new Uint8Array(len);

	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	// Create a Blob from the byte array
	const blob = new Blob([bytes], { type: "image/png" });

	return blob;
};

export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			return resolve(reader.result);
		};
		reader.onerror = () => reject("error");
	});

export const handleError = (error) => {
	if (false == helper.isEmpty(error.errors?.message)) {
		helper.toaster.error(error.errors?.message);
	} else {
		Object?.values(error.errors).map((error) => {
			helper.toaster.error(error);
		});
	}
};

export const _showErrorToaster = (err) => {
	if (err?.errors?.message?.message) {
		toaster.error(err?.errors?.message?.message);
	} else if (err?.errors?.message?.code) {
		toaster.error(err?.errors?.message?.error);
	} else if (err?.errors?.message) {
		toaster.error(err?.errors?.message);
	} else if (err?.errors) {
		if (Array.isArray(err?.errors)) {
			err?.errors.forEach((error) => {
				toaster.error(error);
			});
		} else if (typeof err.errors === "string") {
			toaster.error(err?.errors);
		} else {
			Object.values(err?.errors).forEach((error) => {
				toaster.error(error);
			});
		}
	} else {
		console.error(err);
	}
};

export const handleRedirect = (roleName) => {
	const redirectObj = {
		admin: pageRoutes.admin.home,
		super_admin: pageRoutes.vendor.vendors,
		doctor: pageRoutes.admin.dashboard_doctor,
		patient: pageRoutes.patient.patient_dashboard,
		staff: pageRoutes.admin.appointment,
		prescription: pageRoutes.prescription.appointment,
	};

	return redirectObj[roleName];
};
