import { pageRoutes } from "@/configs";
import { store } from "@/store";
import { handleLogout } from "@/store/toolkit/auth";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SubscriptionPage() {
	const user = useSelector((state) => state.auth?.user);
	const previous_state = useSelector((state) => state.auth?.previousAuth);
	const navigate = useNavigate();

	const handleLogouts = () => {
		if (previous_state.token == null) {
			store.dispatch(handleLogout());
			navigate(user?.role_name == "patient" ? pageRoutes.patient.patient_login : pageRoutes.admin.login);
		} else {
			store.dispatch(handleLogout());
			setTimeout(() => {
				window.location.href = pageRoutes.vendor.vendors;
			}, 200);
		}
	};

	const showAlert = () => {
		if (!window.alert("Your subscription is expired. Contact admin")) {
			handleLogouts();
		}
	};

	useEffect(() => {
		setTimeout(() => {
			showAlert();
		}, 500);
	}, []);

	return (
		<section className="expire--subscription--section">
			<Container fluid className="h-100">
				<div className="w-100 h-100 d-flex flex-column justify-content-center align-item-center">
					<h3 className="text-center">
						Your subscription is expired. <br /> Contact admin
					</h3>
				</div>
			</Container>
		</section>
	);
}
