import { helper } from "@/services";
import { pageRoutes } from "@/configs";

export const adminMenus = [
	{
		id: "dashboard",
		label: "Dashboard",
		icon: "ph:monitor",
		link: "/#",
		subItems: [
			{
				id: "admin dashboard",
				label: "Admin Dashboard",
				icon: "dashicons:admin-users",
				link: pageRoutes.admin.home,
			},
			{
				id: "doctor dashboard",
				label: "Doctor Dashboard",
				icon: "maki:doctor",
				link: pageRoutes.admin.dashboard_doctor,
			},
			{
				id: "patient dashboard",
				label: "Patient Dashboard",
				icon: "fluent:patient-20-filled",
				link: pageRoutes.patient.patient_dashboard,
			},
		],
	},
	{
		id: "home",
		label: "Home",
		icon: "ic:round-home",
		link: "/#",
		subItems: [
			{
				id: "slider",
				label: "Slider",
				icon: "bi:sliders",
				link: pageRoutes.admin.sliders,
			},
			{
				id: "header",
				label: "Header",
				icon: "fa:header",
				link: pageRoutes.admin.headers,
			},
		],
	},
	{
		id: "services",
		label: "Services",
		icon: "heroicons:wrench-16-solid",
		link: pageRoutes.admin.services,
	},
	{
		id: "doctor",
		label: "Doctors",
		icon: "fontisto:doctor",
		link: "/#",
		subItems: [
			{
				id: "department",
				label: "Department",
				icon: "solar:hospital-bold",
				link: pageRoutes.admin.departments,
			},
			{
				id: "doctors",
				label: "Doctors",
				icon: "maki:doctor",
				link: pageRoutes.admin.doctors,
			},
			{
				id: "doctors",
				label: "Global Search",
				icon: "tdesign:user-search",
				link: pageRoutes.admin.global_doctor_search,
			},
		],
	},
	{
		id: "Gallery",
		label: "Gallery",
		icon: "solar:gallery-bold",
		link: pageRoutes.admin.gallery,
	},
	{
		id: "Blog",
		label: "Blog",
		icon: "la:blog",
		link: "/#",
		subItems: [
			{
				id: "blog-category",
				label: "Blog Category",
				icon: "ri:blogger-fill",
				link: pageRoutes.admin.blogCategory,
			},
			{
				id: "Blogs",
				label: "Blog",
				icon: "la:blog",
				link: pageRoutes.admin.blog,
			},
		],
	},
	{
		id: "appointments",
		label: "Appointments",
		icon: "fa6-solid:address-card",
		link: helper?.userRole() == "doctor" ? pageRoutes.admin.today_doctor_appointment : pageRoutes.admin.appointment,
	},
	{
		id: "appointment",
		label: "Appointment",
		icon: "heroicons:wrench-16-solid",
		link: pageRoutes.patient.appointments,
	},
	{
		id: "enquiry",
		label: "Enquiry",
		icon: "ph:question-fill",
		link: pageRoutes.admin.enquiry,
	},
	{
		id: "testimonial",
		label: "Testimonial",
		icon: "bi:quote",
		link: pageRoutes.admin.testimonial,
	},
	{
		id: "branches",
		label: "Branches",
		icon: "eos-icons:branch-outlined",
		link: pageRoutes.admin.branches,
	},
	{
		id: "page-contant",
		label: "Page Contant",
		icon: "material-symbols:list",
		link: "/#",
		subItems: [
			{
				id: "page-section",
				label: "Page Section",
				icon: "fluent:clipboard-task-16-filled",
				link: pageRoutes.admin.pagesSection,
			},
			{
				id: "choose-us",
				label: "Choose Us",
				icon: "gg:check-o",
				link: pageRoutes.admin.chooseUs,
			},
		],
	},
	{
		id: "users",
		label: "Users",
		icon: "mdi:user",
		link: "/#",
		subItems: [
			{
				id: "role",
				label: "Role",
				icon: "mingcute:user-4-fill",
				link: pageRoutes.vendor.role,
			},
			{
				id: "user",
				label: "User",
				icon: "mdi:user-group",
				link: pageRoutes.vendor.user,
			},
			{
				id: "permission",
				label: "Permission",
				icon: "mdi:user-lock",
				link: pageRoutes.vendor.permission,
			},
			{
				id: "staff",
				label: "Staff",
				icon: "clarity:users-solid",
				link: pageRoutes.admin.staff,
			},
		],
	},
	{
		id: "patients",
		label: "Patients",
		icon: "fa-solid:bed",
		link: pageRoutes.admin.all_patients,
	},
	{
		id: "reference",
		label: "Reference",
		icon: "octicon:cross-reference-24",
		link: pageRoutes.admin.reference,
	},
	{
		id: "symptom",
		label: "Symptom",
		icon: "covid:symptoms-fever",
		link: pageRoutes.admin.symptom,
	},
	{
		id: "setting",
		label: "Setting",
		icon: "ant-design:setting-filled",
		link: pageRoutes.admin.setting,
	},
	{
		id: "counter",
		label: "Counter",
		icon: "fa6-solid:cash-register",
		link: pageRoutes.admin.counter,
	},
	{
		id: "holiday",
		label: "Holiday",
		icon: "uim:calender",
		link: pageRoutes.admin.holiday,
	},
	{
		id: "display-tV",
		label: "Display TV",
		icon: "material-symbols-light:connected-tv-outline-sharp",
		link: pageRoutes.admin.appointmentMonitor,
	},
	{
		id: "view-file",
		label: "View file",
		icon: "ic:round-home",
		link: pageRoutes.patient.patient_files,
	},
	{
		id: "intern-doctor",
		label: "Intern doctor",
		icon: "hugeicons:doctor-01",
		link: pageRoutes.admin.intern_doctor,
	},
	{
		id: "vendor",
		label: "vendor",
		icon: "hugeicons:doctor-01",
		link: pageRoutes.vendor.vendors,
	},
	{
		id: "Letter",
		label: "Letter Management",
		icon: "ph:file-text-light",
		link: "/#",
		subItems: [
			{
				id: "letter-pad",
				label: "Letter Format",
				icon: "tabler:list-letters",
				link: pageRoutes.admin.letter_format_content,
			},
			{
				id: "letter-history",
				label: "Letter History",
				icon: "mdi:map-search",
				link: pageRoutes.admin.letter_generate,
			},
		],
	},
];

