import { helper } from "@/services";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { webRoutes } from "./webRoutes";
import AdminLayout from "@/pages/admin/core/layout";
import { filterRoute } from "@/configs/routes";
import { defaultRedirectionLink } from "@/constant/data";
import PageNotFound from "@/components/admin/errors/PageNotFound";
import PrescriptionLayout from "@/pages/admin/core/layout/prescriptionLayout";
import VendorLayout from "@/pages/admin/core/layout/vendor/vendorLayout";
import { getAuthState } from "@/store/selector";
import SubscriptionPage from "@/components/admin/errors/Subscription";
import { WelComePage } from "@/pages/admin/pages/WelComePage";

export default function InitialRoutes() {
	const adminRoutes = webRoutes.getAdminRoutes();
	const redux_data = getAuthState();

	return (
		<>
			<Routes>
				{/* <Route path="*" element={<PageNotFound />} /> */}
				<Route path="/expire" element={<SubscriptionPage />} />

				{webRoutes.guest.map((route, i) => (
					<Route key={i} path={filterRoute(route.path)} element={<GuestRoutes component={route.element} {...route} />} />
				))}

				{["admin", "doctor", "staff"].includes(redux_data?.user?.role_name) && (
					<Route path="/*" element={<AdminLayout />}>
						{adminRoutes?.map((route, i) => {
							return <Route key={i} path={filterRoute(route.path)} element={<PrivateRoutes component={route.element} {...route} />} />;
						})}
						<Route path="*" element={<WelComePage />} />
					</Route>
				)}

				{redux_data?.user?.role_name == "prescription" && (
					<Route path="/*" element={<PrescriptionLayout />}>
						{webRoutes.prescription.map((route, i) => {
							return <Route key={i} path={filterRoute(route.path)} element={<PrivateRoutes component={route.element} {...route} />} />;
						})}
						<Route path="*" element={<WelComePage />} />
					</Route>
				)}

				{redux_data?.user?.role_name == "super_admin" && (
					<Route path="/*" element={<AdminLayout />}>
						{webRoutes.vender.map((route, i) => {
							return <Route key={i} path={filterRoute(route.path)} element={<PrivateRoutes component={route.element} {...route} />} />;
						})}

						<Route path="*" element={<WelComePage />} />
					</Route>
				)}

				{redux_data?.user?.role_name == "patient" && (
					<Route path="/*" element={<AdminLayout />}>
						{webRoutes.patient.map((route, i) => {
							return <Route key={i} path={filterRoute(route.path)} element={<PrivateRoutes component={route.element} {...route} />} />;
						})}
						<Route path="*" element={<WelComePage />} />
					</Route>
				)}
			</Routes>
		</>
	);
}

function GuestRoutes({ component: Component }) {
	let isAuth = helper.isAuth();

	return isAuth ? (
		<Navigate to={defaultRedirectionLink()} />
	) : (
		<Suspense fallback={"Loading..."}>
			<Component />
		</Suspense>
	);
}

function PrivateRoutes({ component: Component }) {
	let isAuth = helper.isAuth();
	return isAuth ? <Component /> : <Navigate to={defaultRedirectionLink()} />;
}
