import ReactModal from "@/components/admin/ui/ReactModal";
import ReactSelect from "@/components/admin/forms/ReactSelect";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { handleError, isEmpty } from "@/services/helper";
import { helper } from "@/services";
import apiConfigNew from "@/configs/apiConfigNew";
import { store } from "@/store";
import { handleLogin } from "@/store/toolkit/auth";
import { useSelector } from "react-redux";
import useFetchData from "@/hooks/useFetchData";
import { pageRoutes } from "@/configs";
import useHandlePostAPI from "@/hooks/useHandlePostAPI";
import axios from "@/services/axios";
import { useNavigate } from "react-router-dom";

const DoctorModal = ({ modalShow, setModalShow, branch, callApi }) => {
	const user = useSelector((state) => state.auth?.user);
	const data = useSelector((state) => state.auth);
	const [branchId, setBranchId] = useState(user?.branch_id);
	const navigate = useNavigate();

	// const [branch, callApi] = useFetchData({
	//     defaultData: [],
	//     endpoints: `${apiConfigNew.admin.get_vendor}`,
	//     callback: (data) => {
	//         return data;
	//     },
	// });

	const handleRedirect = (roleName) => {
		const redirectObj = {
			admin: pageRoutes.admin.home,
			super_admin: pageRoutes.vendor.vendors,
			doctor: pageRoutes.admin.dashboard_doctor,
			patient: pageRoutes.patient.patient_dashboard,
			staff: pageRoutes.admin.appointment,
			prescription: pageRoutes.prescription.appointment,
		};

		return redirectObj[roleName];
	};

	const handleVendor = () => {
		axios
			.put(apiConfigNew.admin.set_vendor_id.replace(":id", branchId))
			.then((res) => {
				store.dispatch(
					handleLogin({
						previousAuth: {
							token: null,
							user: null,
							doctor: null,
							permissions: [],
						},
						...res,
					})
				);
				helper.toaster.success("update successfull");
				setModalShow(false);
				callApi();
				navigate(handleRedirect(user?.role_name));
			})
			.catch((error) => {
				handleError(error);
			});
	};

	useEffect(() => {
		if (branch) {
			const id = branch.filter((item) => item.is_default_vendor == true);
			setBranchId(id[0]?.value);
		}
	}, [branch]);

	return (
		<div>
			<ReactModal
				size="md"
				title="select Vendor"
				show={modalShow}
				btnTitle={"Change"}
				onSave={() => {
					if (!isEmpty(branchId)) {
						handleVendor();
					} else {
						helper.toaster.error("Please Select Vendor");
					}
				}}
				onHide={() => {
					if (user?.branch_id) {
						setModalShow(false);
					} else {
						setModalShow(true);
					}
				}}
				hideCancleIcon={user?.branch_id ? true : false}
			>
				<Col xl={12}>
					<ReactSelect
						placeholder="Select"
						label=""
						onChange={(e) => {
							setBranchId(e?.value);
						}}
						disabled={user?.role_name == "staff"}
						value={branchId}
						notShowSelectText={true}
						options={branch || []}
						className="mx-3"
					/>
				</Col>
			</ReactModal>
		</div>
	);
};

export default DoctorModal;
