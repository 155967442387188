import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { helper } from "@/services";
import useMenuData from "@/hooks/useMenuData";
import ReactButton from "@/components/admin/ui/reactButton";
import { pageRoutes } from "@/configs";
import { isAuth, userRole } from "@/services/helper";
import { store } from "@/store";
import { handleLogout } from "@/store/toolkit/auth";

const Sidebar = () => {
    const [role_name] =useState(userRole())
	const [increaseWidth, setIncreaseWidth] = useState(false);
	const withoutLayoutRoutes = [pageRoutes.admin.appointmentMonitor];
    const is_auth = isAuth()

	const menus = useMenuData();

	const toggleSidebarWidth = () => {
		setIncreaseWidth(!increaseWidth);
	};

	useEffect(() => {
		if (helper.userRole() == "doctor" && !increaseWidth) {
			const deg = 6;
			let hour = document.querySelector(".hour");
			let min = document.querySelector(".min");
			let sec = document.querySelector(".sec");
			const setClock = () => {
				let day = new Date();
				let hh = day.getHours() * 30;
				let mm = day.getMinutes() * deg;
				let ss = day.getSeconds() * deg;
				if (hour !== null) {
					hour["style"]["transform"] = `rotateZ(${hh + mm / 12}deg)`;
				}
				if (min !== null) {
					min["style"]["transform"] = `rotateZ(${mm}deg)`;
				}
				if (sec !== null) {
					sec["style"]["transform"] = `rotateZ(${ss}deg)`;
				}
			};
			const intervals = setInterval(setClock, 500);
			return () => {
				clearInterval(intervals);
			};
		}
	}, [increaseWidth]);

    useEffect(()=>{
        	if (!is_auth) {
				store.dispatch(handleLogout());
				localStorage.clear();
				window.location.href = (role_name == "patient" ? pageRoutes.patient.patient_login : pageRoutes.admin.login);
			}
    },[is_auth])

	return (
		<section className={increaseWidth === false ? "sidebar--section" : "mini--sidebar-section"}>
			<div className="sidebar--header">
				<ReactButton
					variant="transparent"
					size="sm"
					className="toggle--btn"
					onClick={() => {
						toggleSidebarWidth();
					}}
				>
					<Icon icon={increaseWidth === false ? "gg:menu-right" : "ci:menu-alt-03"} className="d-block" />
				</ReactButton>
			</div>
			<div className={`${helper.userRole() == "doctor" ? "custom--height" : "default--height"} sidebar--menu`}>
				<ul className="p-0 m-0">
					{menus.map((item, index) => {
						return (
							<li key={index} className={`sub--menu ${item.isActive ? "active" : "disable"} ${item.isActiveColor ? "active-color" : ""}`}>
								{item.hasOwnProperty("subItems") ? (
									<Button variant="transparent" className="d-flex align-items-center submenu--link" onClick={item.click}>
										<span className="menu--side">
											<Icon icon={item.icon} />
										</span>
										<span className="d-block text-capitalize menu--title">{item.label}</span>
										<span className="menu--arrow d-block">
											<Icon icon={item.isActive ? "iconamoon:arrow-down-2" : "iconamoon:arrow-right-2-duotone"} />
										</span>
									</Button>
								) : (
									<Link
										to={item.link}
										target={withoutLayoutRoutes?.includes(item.link) ? "_blank" : ""}
										className="d-flex align-items-center submenu--link"
									>
										<span className="menu--side">
											<Icon icon={item.icon} />
										</span>
										<span className="d-block text-capitalize menu--title">{item.label}</span>
									</Link>
								)}
								{item.hasOwnProperty("subItems") && (
									<ul className={`sub-menu--list ${item.isActive ? "d-block  sidaebar---ui" : "d-none"}`}>
										{item.subItems.map((subItem, subIndex) => {
											return (
												<li key={subIndex}>
													<Link to={subItem.link} className={`d-flex align-items-center text-capitalize ${subItem.isActive ? "active" : "disable"}`}>
														{subItem.icon && <Icon icon={subItem.icon} className="d-block" />}
														<>{subItem.label}</>
													</Link>
												</li>
											);
										})}
									</ul>
								)}
							</li>
						);
					})}
				</ul>
			</div>
			{helper.userRole() == "doctor" && !increaseWidth && (
				<div className="clock my-4">
					<div className="hour"></div>
					<div className="min"></div>
					<div className="sec"></div>
				</div>
			)}
		</section>
	);
};

export default Sidebar;
