import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { helper } from "../services";
import { useSelector } from "react-redux";
import { checkUserPermission } from "@/constant/permissions";

export default function useMenuData() {
	const [_menus, setMenu] = useState([]);
	const [menus, setMenus] = useState([]);
	const isAuth = helper.isAuth();

	const location = useLocation();
	const navigate = useNavigate();

	const permissions = useSelector((state) => state.auth?.permissions);
	const path = location.pathname;

	useEffect(() => {
		if (helper.isDefined(isAuth)) {
        const {filter_route_data = []} = checkUserPermission()
			setMenu(filter_route_data);
		}
	}, [permissions]);

	const menusData = useMemo(() => {
		return _menus.map((item) => {
			let _item = {
				...item,
				isActive: false,
				click: function (e) {
					e.preventDefault();
					if (item.link != "/#") {
						navigate(item.link);
					} else {
						changeMenu(item.id);
					}
				},
			};

			if (_item.hasOwnProperty("subItems")) {
				_item.subItems = _item.subItems.map((subItem) => {
					return {
						...subItem,
						isActive: false,
						click: function (e) {
							e.preventDefault();
							if (subItem.link != "/#") {
								navigate(subItem.link);
							} else {
								changeMenu(subItem.id);
							}
						},
					};
				});
			}

			return _item;
		});
	}, [_menus]);

	useEffect(() => {
		setMenus(menusData);
	}, [menusData]);

	const changeMenu = (menuId, field = "id", __menus = null) => {
		let _menus = [...(__menus == null ? menusData : __menus)];

		_menus = _menus.map((menu) => {
			menu.isActiveColor = false;
			menu.isActive = menu[field] == menuId ? !menu.isActive : false;

			if (menu.hasOwnProperty("subItems")) {
				let isMainMenuActive = false;

				menu.subItems = menu.subItems.map((subItem) => {
					let isSubMenuActive = false;
					if (subItem[field] == menuId) {
						isMainMenuActive = true;
					}
					subItem.isActive = subItem[field] == menuId ? !subItem.isActive : false;

					if (subItem.hasOwnProperty("childItems")) {
						subItem.childItems = subItem.childItems.map((childItem) => {
							childItem.isActive = childItem[field] == menuId ? !childItem.isActive : false;
							if (childItem[field] == menuId) {
								isMainMenuActive = true;
								isSubMenuActive = true;
							}

							return childItem;
						});
					}

					if (isSubMenuActive) {
						subItem.isActive = isSubMenuActive;
					}

					return subItem;
				});

				if (isMainMenuActive) {
					menu.isActive = isMainMenuActive;
				}

				let _isSubItemActive = menu.subItems.some((e) => e.link == window.location.pathname);

				menu.isActiveColor = _isSubItemActive;

				return menu;
			}

			return menu;
		});

		if (field == "id") {
			_menus = activateCurrentRoute(window.location.pathname, _menus);
		}

		setMenus([..._menus]);
	};

	const activateCurrentRoute = (menuId, ___menus) => {
		let _menus = [...___menus];

		_menus = _menus.map((menu) => {
			menu.isActive = menu["link"] == menuId ? true : menu.isActive;
			if (menu.hasOwnProperty("subItems")) {
				menu.subItems = menu.subItems.map((subItem) => {
					subItem.isActive = subItem["link"] == menuId ? true : subItem.isActive;

					if (subItem.hasOwnProperty("childItems")) {
						subItem.childItems = subItem.childItems.map((childItem) => {
							childItem.isActive = childItem["link"] == menuId ? true : childItem.isActive;
							return childItem;
						});
					}

					return subItem;
				});

				return menu;
			}

			return menu;
		});

		return _menus;
	};

	useEffect(() => {
		const initMenu = () => {
			changeMenu(window.location.pathname, "link");
		};

		if (!helper.isEmpty(menusData)) {
			initMenu();
		}
	}, [path, menusData]);

	return menus;
}
