import { themeConfig } from "@/configs";
import React from "react";

export const WelComePage = () => {
	return (
		<div className="welcome-page-wrapper">
			<div className="main--div">
				<img src={themeConfig.WelcomePage} alt=".." />
				<p>Hello, nice to see you!</p>
				<span>Welcome to the hospital management system</span>
			</div>
		</div>
	);
};
