import React from "react";
import "./../assets/styles/main.min.css";
import "react-quill/dist/quill.snow.css";
import InitialRoutes from "./routes/InitialRoutes";

function App() {
	return (
		<>
			<InitialRoutes />
		</>
	);
}

export default App;
