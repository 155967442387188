import { appConfig, pageRoutes } from "@/configs";
import { helper } from "@/services";
import { handleRedirect } from "@/services/helper";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	previousAuth: {
		token: null,
		user: null,
		doctor: null,
		permissions: [],
	},
	token: null,
	user: null,
	doctor: null,
	permissions: [],
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		updateUserDetail: (state, action) => {
			state = {
				...state,
				user: action.payload,
			};
			return state;
		},
		updateDoctorDetail: (state, action) => {
			state = {
				...state,
				doctor: action.payload,
			};
			return state;
		},
		updateUserPermission: (state, action) => {
			state = {
				...state,
				permissions: action.payload,
			};
			return state;
		},
		handleLogin: (state, action) => {
			state = {
				...state,
				previousAuth: state.previousAuth,
				token: action.payload.token,
				user: action.payload.user,
				permissions: action.payload?.permissions || [],
				doctor: action.payload?.doctor || null,
			};
			window.localStorage.setItem(appConfig.localStorage.token, action.payload.token);
			window.localStorage.setItem(appConfig.localStorage.role, action.payload.user?.role_name);
			return state;
		},

		handleLogout: (state, action) => {
			const previousAuth = state.previousAuth || {};

			window.localStorage.removeItem(appConfig.localStorage.token);
			window.localStorage.removeItem(appConfig.localStorage.role);
			if (previousAuth.token) {
				window.localStorage.setItem(appConfig.localStorage.token, previousAuth.token);
			}
			state = {
				...initialState.previousAuth,
				token: previousAuth.token || null,
				user: previousAuth.user || null,
				doctor: previousAuth.doctor || null,
				permissions: previousAuth.permissions || [],
			};

			return state;
		},
		savePreviousAuth: (state, action) => {
			let previousAuth = {
				token: state?.token || null,
				user: state?.user || null,
				permissions: state?.permissions || [],
				doctor: state?.doctor || null,
			};
			window.localStorage.setItem(appConfig.localStorage.token, action.payload.token);
			window.localStorage.setItem(appConfig.localStorage.role, action.payload.user?.role_name);
			return {
				previousAuth,
				token: action.payload.token,
				user: action.payload.user,
				permissions: action.payload?.permissions || [],
				doctor: action.payload?.doctor || null,
			};
		},
	},
});

export default authSlice.reducer;
export const { handleLogin, handleLogout, handleDoctorLogin, updateDoctorDetail, updateUserDetail, updateUserPermission, savePreviousAuth } =
	authSlice.actions;
