import plusIcon from "@assets/images/abou-medical-icon.png";
import emergencyCall24 from "@assets/images/24-hours-phone-service.png";
import dotBgImg from "@assets/images/pagebg-small1-half.png";
import appointmentBg from "@assets/images/appointment-bg.jpg";
import clockImg from "@assets/images/clock-img.png";
import teamIcon from "@assets/images/Icon-team.png";
import stethoscopeImg from "@assets/images/stethoscop.png";
import rattingIcon from "@assets/images/rating-icon.png";
import loginImg from "@assets/images/login-02.png";
import loginLogo from "@assets/images/login-logo.png";
import mainLogo from "@assets/images/main-logo.png";
import headerLogo from "@assets/images/logo.png";
import headerUserImg from "@assets/images/user-06.jpg";
import NoData from "@assets/images/no-data.svg";
import doctorImg from "@assets/images/about-doctor.avif";
import topServiceImg from "@assets/images/Icon-team.png";
import aboutChooseUsImg from "@assets/images/about-us.jpg";
import serviceDoctorImg from "@assets/images/service-doctor.avif";
import galleryImg from "@assets/images/gallery-img-1.avif";
import morningImg from "@assets/images/morning-img-01.png";
import patientIcon from "@assets/images/user-icon.svg";
import profileImg from "@assets/images/paras-dobriya.avif";
import cardImage from "@assets/images/gallery-image.jpg";
import avtarImage from "@assets/images/avtar.png";
import notFoundImage from "@assets/images/notFound.png";
import pencil from "@assets/images/pen.png";
import eraser from "@assets/images/eraser.png";
import canvasBg from "@assets/images/canvasBG.png";
import blankImage from "@assets/images/blankImage.jpg";
import notificationGif from "@assets/images/notification.gif";
import doctorDash from "@assets/images/morning-img-02.png";
import doctorProfileImg from "@assets/images/avatar-02.jpg";
import patientDash from "@assets/images/morning-img-03.png";
import HeartRate from "@assets/images/report-icon-01.svg";
import Temperature from "@assets/images/report-icon-02.svg";
import BloodPressure from "@assets/images/report-icon-03.svg";
import Sleep from "@assets/images/report-icon-04.svg";
import DoctorDP from "@assets/images/avatar-05.jpg";
import doctorTimer from "@assets/images/timer.svg";
import healthImg from "@assets/images/health-img.svg";
import noImageFound from "@assets/images/no-image-found.png";
import defaultIamge from "@assets/images/default-image.png";
import noSliderOrBanner from "@assets/images/hero-bg.jpg";
import prescriptionFirstImage from "@assets/prescription_first_page/first_page.jpeg";
import logoDefaultImage from "@assets/images/logo-default-image.png";
import footerDefaultImage from "@assets/images/footer_default_image.png";
import sliderDefaultImage from "@assets/images/slider-default-image.png";
import chooseusDefaultImage from "@assets/images/choose-us-image.png";
import serviceDefaultImage from "@assets/images/service-default-image.png";
import serviceHomeDefaultImage from "@assets/images/service-home-default-image.png";
import blogHomeDefaultImage from "@assets/images/blog-default-image.png";
import doctorHomeDefault from "@assets/images/doctor-home1-image.png";
import googleHomeImage from "@assets/images/google5-home.png";
import aboutUsHomeImage from "@assets/images/about-us-home.png";
import openingHoursImage from "@assets/images/opening-hour-2.png";
import doctorImage from "@assets/images/doctor-image.png";
import whiteBg from "@assets/images/white-bg.png";
import internamMedicine from "@assets/images/internal-medicine.svg";
import emergency from "@assets/images/emergency.svg";
import urology from "@assets/images/urology.svg";
import pediatrics from "@assets/images/pediatrics.svg";
import emptyImage from "@assets/images/empty.svg";
import errorImage from "@assets/images/error-404.png";
import eyeImage from "@assets/images/eye.svg";
import editImage from "@assets/images/edit.svg";
import deleteImage from "@assets/images/delete.svg";
import noimg from "@assets/images/noimg.png";
import Expire from "@assets/images/expire.jpg";
import WelcomePage from "@assets/images/welcome.svg";

const themeConfig = {
	deleteImage,
	editImage,
	pediatrics,
	urology,
	emergency,
	internamMedicine,
	whiteBg,
	sliderDefaultImage,
	chooseusDefaultImage,
	serviceDefaultImage,
	serviceHomeDefaultImage,
	blogHomeDefaultImage,
	doctorHomeDefault,
	googleHomeImage,
	aboutUsHomeImage,
	openingHoursImage,
	doctorImage,
	footerDefaultImage,
	logoDefaultImage,
	loginImg,
	loginLogo,
	plusIcon,
	emergencyCall24,
	clockImg,
	teamIcon,
	stethoscopeImg,
	rattingIcon,
	mainLogo,
	headerLogo,
	headerUserImg,
	NoData,
	doctorImg,
	topServiceImg,
	aboutChooseUsImg,
	serviceDoctorImg,
	galleryImg,
	morningImg,
	patientIcon,
	profileImg,
	cardImage,
	avtarImage,
	notFoundImage,
	pencil,
	eraser,
	canvasBg,
	blankImage,
	notificationGif,
	doctorDash,
	doctorProfileImg,
	patientDash,
	HeartRate,
	BloodPressure,
	Sleep,
	Temperature,
	DoctorDP,
	doctorTimer,
	healthImg,
	noImageFound,
	defaultIamge,
	noSliderOrBanner,
	prescriptionFirstImage,
	dotBgImg,
	appointmentBg,
	emptyImage,
	errorImage,
	eyeImage,
	noimg,
	Expire,
	WelcomePage,
};

export default themeConfig;
